import Panel from "components/Panel";
import PageHeader from "components/PageHeader";
import { useApi } from "components/useApi3";
import moment from "moment";
import { useEffect } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import EditForm from "./components/EditForm";
import { Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
// two tabbar, en för "hantera ärende" och en för "skadeinformation"
// det man hämtar och redigerar är en IncidentHandleFormDto som är step1+step2+handle

const PageLabel = ({ result }) => {
	const data =
		result.status === 2
			? result.data
			: {
					insuranceCategory: "",
					location: "",
					crashCategory: "",
					theftItems: "",
					fireType: "",
					vehicleDisplayName: "",
					incidentDate: "",
			  };
	const title =
		data.insuranceCategory === "CRASH"
			? data.crashCategory
			: data.insuranceCategory === "THEFT"
			? data.theftItems
			: data.insuranceCategory === "FIRE"
			? data.fireType
			: "".concat(" - ").concat(data.vehicleDisplayName);
	const subtitle = data.location.concat(" | ").concat(data.incidentDate ? moment(data.incidentDate).format("YYYY-MM-DD") : "");

	return <PageHeader title={title} subtitle={subtitle} />;
};

const IncidentEdit = ({ match }) => {
	const [itemResult, itemApi] = useApi();
	const [saveResult, saveApi] = useApi();
	const [sendResult, sendApi] = useApi();

	const history = useHistory();

	useEffect(() => {
		itemApi.get("api/incident/incidents/".concat(match.params.id));
	}, [itemApi, match]);
	const [vehiclesResult, vehiclesApi] = useApi();

	useEffect(() => {
		vehiclesApi.get("api/system/vehicles/select");
	}, [vehiclesApi]);

	useEffect(() => {
		if (sendResult.status === 2){
			history.push('/incidents/send/'.concat(match.params.id));
		}
	},[sendResult, history, match])

	const vehicles = vehiclesResult.status === 2 ? vehiclesResult.data : [];

	const editItem =
		itemResult.status === 2
			? {
					...itemResult.data,
					incidentDate: moment(itemResult.data.incidentDate),
					followUpTalkDate: itemResult.data.followUpTalkDate ? moment(itemResult.data.followUpTalkDate) : "",
					handlingDoneDate: itemResult.data.handlingDoneDate ? moment(itemResult.data.handlingDoneDate) : "",
					mileage: itemResult.data.mileage ? itemResult.data.mileage : 0,
					numberOfPassengers: itemResult.data.numberOfPassengers ? itemResult.data.numberOfPassengers : 0,
			  }
			: null;

	return (
		<>
			<PageLabel result={itemResult} />
			<Panel>
				{itemResult.status === 1 && <LoadingSpinner />}
				{itemResult.status === 2 && vehiclesResult.status === 2 && (
					<EditForm
						onSubmit={(values) => {
							const saveMe = {
								...values,
								numberOfPassengers: parseInt(values.numberOfPassengers),
								mileage: parseInt(values.mileage),
								incidentDate: values.incidentDate.toISOString(),
								followUpTalkDate: values.followUpTalkDate !== "" ? values.followUpTalkDate.toISOString() : null,
								handlingDoneDate: values.handlingDoneDate !== "" ? values.handlingDoneDate.toISOString() : null,
							};

							saveApi.put("api/incident/incidents", saveMe);

						}}
						onSubmitSend={(values) => {
							
							const saveMe = {
								...values,
								numberOfPassengers: parseInt(values.numberOfPassengers),
								mileage: parseInt(values.mileage),
								incidentDate: values.incidentDate.toISOString(),
								followUpTalkDate: values.followUpTalkDate !== "" ? values.followUpTalkDate.toISOString() : null,
								handlingDoneDate: values.handlingDoneDate !== "" ? values.handlingDoneDate.toISOString() : null,
							};

							sendApi.put("api/incident/incidents", saveMe);

						}}
						vehicles={vehicles}
						vehiclesStatus={vehiclesResult.status}

						initialValues={editItem}
					/>
				)}
				{saveResult.status > 2 && <Alert color="danger">Ett fel inträffade. Kunde inte spara ärendet.</Alert>}
				{saveResult.status === 1 && <Alert color="info">Sparar ärendet..</Alert>}
				{saveResult.status === 2 && <Alert color="info">Ärendet sparat.</Alert>}
				{sendResult.status > 2 && <Alert color="danger">Ett fel inträffade. Kunde inte spara ärendet.</Alert>}
				{sendResult.status === 1 && <Alert color="info">Sparar ärendet..</Alert>}
			</Panel>
		</>
	);
};
export default IncidentEdit;
