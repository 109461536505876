import * as Yup from "yup";
export const initialValuesStep1 = {
    insuranceCategory: "", // crash, theft, fire
    crashCategory: "",
    theftItems: "",
    fireType: "",
    isTrafficCollision: false, // möteskollision
    estimatedRoadWidth: "",
    damageLocationCategory: [],// var inträffade skadan
    incidentDate: "",
}
export const initialValuesStep2 = {
    vehicleId: "", // måste mappa upp det som är valt från vehicles till vehicleId vid spara
    mileage: "",
    location: "", // plats
    numberOfPassengers: "",
    descriptionOfEvents: "",
    replacementVehicleHired: false,
    visibleDamages: false,
    visibleDamagesDescription: "",
    personalDamages: false,
    personalDamagesDescription: "",
    images: [],
    counterPartContactInformations: [], // kontaktuppgifter motpart
    witnesses: "",
    policeContacted: false,
    emergencyServiceContacted: false,
    bloodTest: false,
    breathTest: false,
    isVehicleTowed: false,
    vehicleTowedToLocation: "",
}

export const initialValuesHandle = {
    isInsuranceMatter: false,
    towingCompany: "",
    estimatedCostForInjury: "",
    counterpartCost: "",
    materialDamages: "",
    followUpTalkDate: "",
    action: "", // åtgärd
    additionalNotes: "", // övriga noteringar
    status: "",
    damageNumber: "",
    supplementedForInsuranceCompany: false,
    result: "", // utfall
    handlingDoneDate: "", // reglering klar
    compensationAmount: "", // ersättningbelopp
};


export const step1ValidationSchema = Yup.object().shape({
	insuranceCategory: Yup.string().required("Försäkringskategori måste anges"),
    damageLocationCategory: Yup.array().min(1,"Minst en kategori måste väljas"),
    incidentDate: Yup.date().required("Datum för incident måste anges")
});

export const step2ValidationSchema = Yup.object().shape({
    vehicleId: Yup.number().required("Ett Fordon måste anges"),
    mileage: Yup.number().required("Mätarställning måste anges"),
    numberOfPassengers: Yup.number().required("Antal passagerare måste anges"),
    descriptionOfEvents: Yup.string().required("Händelsebeskrivning måste anges"),
    location: Yup.string().required("Plats måste anges")
}) 

export const handleValidationSchema = Yup.object().shape({
    status: Yup.string().required("Status måste anges")
})

export const allSchemasCombined = () => {
    return mergeSchemas(step1ValidationSchema, step2ValidationSchema, handleValidationSchema);
}

function mergeSchemas(...schemas) {
    const [first, ...rest] = schemas;
  
    const merged = rest.reduce(
      (mergedSchemas, schema) => mergedSchemas.concat(schema),
      first
    );
  
    return merged;
  }

// usage:
//const merged = merge(schema1, schema2, schema3, etc);