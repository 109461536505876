import AlertResultFeedback from "components/AlertResultFeedback";
import { useApi } from "components/useApi3";

const Diagnostics = () => {
	const [result, api] = useApi();
	const [result2, api2] = useApi();

	return (
		<div>
			<button
				onClick={() => {
					api.get("/api/diagnostics/test/unauth");
				}}
			>
				test unauth
			</button>
			<AlertResultFeedback result={result} />
			<button
				onClick={() => {
					api2.get("/api/diagnostics/test/isauth");
				}}
			>
				test isauth
			</button>
			<AlertResultFeedback result={result2} />
		</div>
	);
};
export default Diagnostics;
