import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import numeral from "numeral";
import moment from "moment";
import "moment/locale/sv";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


numeral.defaultFormat("0,0.00");
numeral.register("locale", "fr", {
	delimiters: {
		thousands: " ",
		decimal: ",",
	},
	abbreviations: {
		thousand: "k",
		million: "m",
		billion: "b",
		trillion: "t",
	},
	ordinal: function (number) {
		return number === 1 ? "er" : "ème";
	},
	currency: {
		symbol: "€",
	},
});
numeral.locale("fr");

moment().locale("sv");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);
