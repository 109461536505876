import React, { useEffect, useMemo, useState } from "react";
import { useApi } from "components/useApi3";
import DataTable from "components/DataTable";

import PageHeader from "components/PageHeader";
import Panel from "components/Panel";
import { Button, ButtonGroup, Col, FormGroup } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import incidentCategory from "components/IncidentCatogory";
import IncidentStatus, { incidentStatusName } from "components/IncidentStatus";
import IncidentProgress from "components/IncidentProgress";
import Switch from "components/InputSwitch";

const MyIncidents = () => {
	const history = useHistory();

	const [listResult, listApi] = useApi();

	const [filters, setFilters] = useState({
		statusOpen: true,
		statusSent: true,
		statusCompleted: true,
	});

	useEffect(() => {
		listApi.get(
			"api/incident/mine?open=".concat(filters.statusOpen).concat("&sent=").concat(filters.statusSent).concat("&completed=").concat(filters.statusCompleted)
		);
	}, [listApi, filters]);

	const columns = useMemo(
		() => [
			{
				accessor: row => incidentCategory(row.incidentVehicleDamage.insuranceCategory),
				Header: "Ärende",
			},
			{
				accessor: "incidentDate",
				Header: "Incidentdatum",
				Cell: (cell) => {
					return moment(cell.row.original.incidentDate).format("YYYY-MM-DD");
				},
			},
			{
				accessor: (row) => row.vehicleIdentifier.concat(" - ").concat(row.vehicleRegistrationNumber).concat(" - ").concat(row.vehicleModel),
				Header: "Fordon",
			},
			{
				accessor: "statusProgress",
				Header: "",
				Cell: (cell) => {
					return <IncidentProgress status={cell.row.original.status} />;
				},
			},
			{
				accessor: row => incidentStatusName(row.status),
				Header: "Status",
				Cell: (cell) => {
					return <IncidentStatus status={cell.row.original.status} />;
				},
			},
			{
				accessor: "commands",
				Cell: (cell) => {
					return (
						<ButtonGroup className="float-end">
							<Button
								color="white"
								onClick={() => {
									history.push("/incidents/view/".concat(cell.row.original.id));
								}}
							>
								Detaljer
							</Button>
						</ButtonGroup>
					);
				},
			},
		],
		[history]
	);

	return (
		<>
			<PageHeader title="Mina ärenden" subtitle="Här kan du följa dina inskickade skadeanmälningar" />
			<Panel>
				<DataTable
					columns={columns}
					result={listResult}
					externalFilters={
						<Col xs={12} className="d-flex justify-content-end">
							<FormGroup className="me-2">
								<Switch
									onChange={() => {
										setFilters((f) => {
											return {
												...f,
												statusOpen: !f.statusOpen,
											};
										});
									}}
									checked={filters.statusOpen}
									label="Öppen"
									className="me-2"
								/>
							</FormGroup>
							<FormGroup className="me-2">
								<Switch
									onChange={() => {
										setFilters((f) => {
											return {
												...f,
												statusSent: !f.statusSent,
											};
										});
									}}
									checked={filters.statusSent}
									label="Skickad"
									className="me-2"
								/>
							</FormGroup>
							<FormGroup>
								<Switch
									type="checkbox"
									onChange={() => {
										setFilters((f) => {
											return {
												...f,
												statusCompleted: !f.statusCompleted,
											};
										});
									}}
									checked={filters.statusCompleted}
									label="Avslutad"
								/>
							</FormGroup>
						</Col>
					}
				/>
			</Panel>
		</>
	);
};
export default MyIncidents;
