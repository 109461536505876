import { Line } from "react-chartjs-2";

const options = {
	scales: {
		x: {
			type: "time",
			time: {
				unit: "month",
			},
		},
		y: {
			suggestedMax: 100000,
			min: 0,
			ticks: {
				stepSize: 100000,
			},
		},
	},
};

const Chart = ({ result }) => {
	const siteChartData = result.status === 2 ? result.data.siteChartData : null;

	let chDatasets = [];

	if (siteChartData != null && siteChartData.length > 0) {
		chDatasets = siteChartData.map((item) => {
			const color = "rgb(" + Math.floor(Math.random() * 255) + ", " + Math.floor(Math.random() * 255) + ", " + Math.floor(Math.random() * 255) + ")";

			return {
				label: item.name,
				data: item.values.map((item) => {
					return {
						x: item.date,
						y: item.incidentCost,
					};
				}),
				fill: false,
				backgroundColor: color,
				borderColor: color,
			};
		});
	}

	const chData = { datasets: chDatasets };

	return <Line data={chData} options={options} />;
};
export default Chart;
