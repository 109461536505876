import { useState, useCallback } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';

const useTableExport = () => {
    const [state, setState] = useState(0); // samma som api, 0 inte startad, 1 in progress, 2 finished, 3 failed
    const exportTable = useCallback((columns, rows, prepareRow) => {
        setState(1);
        console.log('exportera', rows);
        setTimeout(() => {
            try {
                rows.forEach((r) => {
                    prepareRow(r);
                });

                let newColumns = [];
                let newRows = [];

                columns.forEach((col) => {
                    newColumns.push(col.Header);
                });

                rows.forEach((row) => {
                    let newRow = [];
                    row.cells
                        .filter((x) => !x.column.hidden)
                        .forEach((cell) => {
                            newRow.push(cell.value);
                        });
                    newRows.push(newRow);
                });
               
                let data = newRows.slice();
                data.unshift(newColumns);

                const ws = XLSX.utils.aoa_to_sheet(data);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
                /* generate XLSX file and send to client */
                XLSX.writeFile(wb, "skadestopplus_export.xlsx");

                setState(2);
            } catch (e) {
                console.log(e);
                setState(3);
            }
        }, 500);
    }, []);

    return [state, exportTable];
};

export default useTableExport;
