import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

const AdminNavigation = () => {
    return <Nav className="nav-tabs">
    <NavItem>
        <NavLink
            exact
            to="/admin/company"                        
        >
            Bolag
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            exact
            to="/admin/company/users"
            >
            Användare
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            exact
            to="/admin/company/sites"
            >
            Orter
        </NavLink>
    </NavItem>
    <NavItem>
        <NavLink
            exact
            to="/admin/company/vehicles"
            >
            Fordon
        </NavLink>
    </NavItem>
</Nav>
}
export default AdminNavigation;
