import FormatCurrency from "components/FormatCurrency";
import HilightCard from "components/HilightCard";
import { Col, Row } from "reactstrap";

const InfoCard = ({result}) => {

	const finished = result.status === 2;
	const data = result.data;

	return (
		<HilightCard>
			<Row>
				<Col>
					<div className="d-flex justify-content-center mb-2">
						<label>Preliminär kostnad</label>
					</div>
					<div className="d-flex justify-content-center large-value mb-2">{ !finished ? "-" : <FormatCurrency value={data.preliminaryCost} />}</div>
					<div className="d-flex justify-content-between mb-2">
						<div>
							<label>Krock</label>
						</div>
						<div className="d-flex justify-content-end">
							<span className="small-value">{!finished ? "-" : data.crashAmount}</span>
						</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<div>
							<label>Stöld/inbrott</label>
						</div>
						<div className="d-flex justify-content-end">
							<span className="small-value">{!finished ? "-" : data.theftAmount}</span>
						</div>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<div>
							<label>Brand</label>
						</div>
						<div className="d-flex justify-content-end">
							<span className="small-value">{!finished ? "-" : data.fireAmount}</span>
						</div>
					</div>
					<div className="d-flex justify-content-between">
						<div>
							<label>Total skadekostnad</label>
						</div>
						<div className="d-flex justify-content-end">
							<span className="small-value">{ !finished ? "-" : <FormatCurrency value={data.totalCost} />}</span>
						</div>
					</div>
				</Col>
			</Row>
		</HilightCard>
	);
};
export default InfoCard;
