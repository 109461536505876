import React from 'react';
import Switch from './InputSwitch';

const InputSwitchMultiChoice = ({options, selected, setSelected}) => {
    
    return <div>

    { options.map((item, key) => {
        
        const checked = selected.indexOf(item) !== -1;

        return <Switch key={key} type="checkbox" checked={checked} onChange={() => {

            var filteredSelection = selected.filter((obj) => obj !== item);
            if (!checked) {
                
                filteredSelection.push(item);
            }

            setSelected(filteredSelection);

        }} label={item} />

    })}

    </div>


}

export default InputSwitchMultiChoice;