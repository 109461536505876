import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav } from "reactstrap";
import { LoginMenu } from "./api-authorization/LoginMenu";

const Topnav = ({ toggleSidebar }) => {
	return (
		<Navbar light className="navbar shadow-sm p-1 bg-white" expand="md">
			<Button color="primary" className='px-2 menu-toggle' onClick={toggleSidebar}>
				<FontAwesomeIcon className='m-0' icon={faAlignLeft} />
			</Button>
			<Nav className="ms-auto" navbar>
				<LoginMenu />
			</Nav>
		</Navbar>
	);
};

export default Topnav;
