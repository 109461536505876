import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import {
	Dropdown,
	DropdownItem,
	DropdownToggle,
	DropdownMenu,
} from "reactstrap";
export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        const toggleDropdown = () => {
			this.setState({ dropDownOpen: !this.state.dropDownOpen });
		};
        return (<Fragment>
				<Dropdown nav isOpen={this.state.dropDownOpen} toggle={toggleDropdown}>
					<DropdownToggle nav caret>
						{userName}
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem>
							<NavLink tag={Link} to={logoutPath}>
								Logga ut
							</NavLink>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Logga in</NavLink>
            </NavItem>
        </Fragment>);
    }
}
