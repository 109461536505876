// Skapa, steg1 och steg2, och hantera Skadeinformation
import { FormGroup, Label, Input, Col, Row } from "reactstrap";
import crash from "assets/crash.png";
import theft from "assets/theft.png";
import fire from "assets/fire.png";
import Switch from "components/InputSwitch";
import InputSwitchMultiChoice from "components/InputSwitchMultiChoice";
import HelpDialog from "components/HelpDialog";
import SplitDatetime from "components/SplitDatetime";
import { useFormikContext } from "formik";
import { useEffect } from "react";
export const StepOneFormItems = ({ values, errors, touched, handleBlur, handleChange, setFieldValue, editMode }) => {
	
	const { submitCount, isValid } = useFormikContext();

	useEffect(() => {

		if (!isValid)
		{
			const errorElement = document.querySelector('#topOfForm');

			if (errorElement) {
				//console.log("scrolling to element", errorElement);
				errorElement.scrollIntoView({ behavior: "smooth" });
			}
		}
	},[submitCount, isValid])
	
	return (
		<>
			{editMode ? (
				<Row className="mb-3">
					<Col xs={12}>
						<FormGroup>
							<Switch onChange={() => setFieldValue("insuranceCategory", "CRASH")} checked={values.insuranceCategory === "CRASH"} label="Krock" />
						</FormGroup>
						<FormGroup>
							<Switch onChange={() => setFieldValue("insuranceCategory", "THEFT")} checked={values.insuranceCategory === "THEFT"} label="Stöld/inbrott" />
						</FormGroup>
						<FormGroup>
							<Switch onChange={() => setFieldValue("insuranceCategory", "FIRE")} checked={values.insuranceCategory === "FIRE"} label="Brand" />
						</FormGroup>
					</Col>
					<Col xs={12}>{errors.insuranceCategory ? <div className="invalid-feedback d-block">{errors.insuranceCategory}</div> : null}</Col>
				</Row>
			) : (
				<Row className="mb-3">
					<Col xs={12} lg={4}>
						<div
							className={values.insuranceCategory === "CRASH" ? "category-button selected" : "category-button"}
							color="white"
							onClick={() => setFieldValue("insuranceCategory", "CRASH")}
						>
							<img src={crash} alt="Krock" />
							<p>Krock, trafikolycka eller skada på fordon</p>
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div
							className={values.insuranceCategory === "THEFT" ? "category-button selected" : "category-button"}
							color="white"
							onClick={() => setFieldValue("insuranceCategory", "THEFT")}
						>
							<img src={theft} alt="Stöld/Inbrott" />
							<p>Stöld eller inbrott</p>
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div
							className={values.insuranceCategory === "FIRE" ? "category-button selected" : "category-button"}
							color="white"
							onClick={() => setFieldValue("insuranceCategory", "FIRE")}
						>
							<img src={fire} alt="Brand" />
							<p>Brand</p>
						</div>
					</Col>
					<Col xs={12}>{errors.insuranceCategory ? <div className="invalid-feedback d-block">{errors.insuranceCategory}</div> : null}</Col>
				</Row>
			)}
			{values.insuranceCategory === "CRASH" && (
				<Row>
					<Col xs={12}>
						<label>Hur inträffade skadan?</label>
					</Col>
					<Col sm={4}>
						<FormGroup>
							<Switch onChange={() => setFieldValue("crashCategory", "Singelolycka")} checked={values.crashCategory === "Singelolycka"} label="Singelolycka" />
						</FormGroup>
						<FormGroup>
							<Switch
								onChange={() => setFieldValue("crashCategory", "Vilt eller djurolycka")}
								checked={values.crashCategory === "Vilt eller djurolycka"}
								label="Vilt eller djurolycka"
							/>
						</FormGroup>
						<FormGroup>
							<Switch
								onChange={() => setFieldValue("crashCategory", "Parkeringsskada")}
								checked={values.crashCategory === "Parkeringsskada"}
								label="Parkeringsskada"
							/>
						</FormGroup>
						<FormGroup>
							<Switch
								onChange={() => setFieldValue("crashCategory", "Smitningsolycka")}
								checked={values.crashCategory === "Smitningsolycka"}
								label="Smitningsolycka"
							/>
						</FormGroup>
						<FormGroup>
							<Switch
								onChange={() => setFieldValue("crashCategory", "Motor eller elektronik")}
								checked={values.crashCategory === "Motor eller elektronik"}
								label="Motor eller elektronik"
							/>
						</FormGroup>
					</Col>
					<Col sm={4}>
						<FormGroup>
							<Switch onChange={() => setFieldValue("crashCategory", "Glasskada")} checked={values.crashCategory === "Glasskada"} label="Glasskada" />
						</FormGroup>
						<FormGroup>
							<Switch onChange={() => setFieldValue("crashCategory", "Skadegörelse")} checked={values.crashCategory === "Skadegörelse"} label="Skadegörelse" />
						</FormGroup>
						<FormGroup>
							<Switch onChange={() => setFieldValue("crashCategory", "Kollision")} checked={values.crashCategory === "Kollision"} label="Kollision" />
						</FormGroup>
						<FormGroup>
							<Switch onChange={() => setFieldValue("crashCategory", "Annat")} checked={values.crashCategory === "Annat"} label="Annat" />
						</FormGroup>
					</Col>
				</Row>
			)}
			{values.crashCategory === "Kollision" && (
				<Row>
					<Col xs={12} lg={4}>
						<FormGroup className="mt-2">
							<Switch
								onChange={() => setFieldValue("isTrafficCollision", !values.isTrafficCollision)}
								checked={values.isTrafficCollision}
								label="Var det en möteskollision?"
							/>
						</FormGroup>
					</Col>
				</Row>
			)}
			{values.isTrafficCollision && (
				<Row>
					<Col sm={6}>
						<FormGroup>
							<Label>Vägens ungefärliga bredd</Label>
							<Input
								className={errors.estimatedRoadWidth ? "is-invalid" : ""}
								autoComplete="off"
								name="estimatedRoadWidth"
								value={values.estimatedRoadWidth}
								onChange={handleChange}
								onBlur={handleBlur}
							></Input>
						</FormGroup>
					</Col>
				</Row>
			)}
			{values.insuranceCategory === "THEFT" && (
				<Row>
					<Col sm={6}>
						<FormGroup>
							<Label>Vad har blivit stulet</Label>
							<Input
								className={errors.theftItems ? "is-invalid" : ""}
								autoComplete="off"
								name="theftItems"
								value={values.theftItems}
								onChange={handleChange}
								onBlur={handleBlur}
							></Input>
						</FormGroup>
					</Col>
				</Row>
			)}
			{values.insuranceCategory === "FIRE" && (
				<Row>
					<Col xs={6}>
						<FormGroup>
							<Label>Typ av brand</Label>
							<Input
								className={errors.fireType ? "is-invalid" : ""}
								autoComplete="off"
								name="fireType"
								value={values.fireType}
								onChange={handleChange}
								onBlur={handleBlur}
							></Input>
						</FormGroup>
					</Col>
				</Row>
			)}
			<Row className="border-top border-bottom pt-3 mb-3">
				<Col xs={12} lg={4}>
					<FormGroup>
						<Label>Var inträffade skadan? </Label>
						<InputSwitchMultiChoice
							options={["Garage/Garageplan", "Annan parkeringsplats", "Allmän väg", "Resecentrum eller motsvarande", "Övrigt"]}
							selected={values.damageLocationCategory}
							setSelected={(value) => {
								setFieldValue("damageLocationCategory", value);
							}}
						/>
						{errors.damageLocationCategory ? <div className="invalid-feedback d-block">{errors.damageLocationCategory}</div> : null}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} lg={4}>
					<FormGroup>
						<Label>När hände det?</Label>
						<HelpDialog title="Datum för incident" message="Ange datum och tid när incidenten inträffade" />
						<SplitDatetime
							onChange={(value) => {
								setFieldValue("incidentDate", value);
							}}
							errors={errors}
							value={values.incidentDate}
							name="incidentDate"
						/>
						{errors.incidentDate ? <div className="invalid-feedback d-block">{errors.incidentDate}</div> : null}
					</FormGroup>
				</Col>
			</Row>
		</>
	);
};
