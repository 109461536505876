import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faSpinner,
} from "@fortawesome/pro-light-svg-icons";

const LoadingSpinner = () => {
    return <div className="d-flex justify-content-center">
        <FontAwesomeIcon icon={faSpinner} spin size="4x" />
    </div>
}
export default LoadingSpinner;
