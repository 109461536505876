import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import numeral from "numeral";

const NumberField = ({ value, onChange, className, onBlur, name }) => {
	const [localValue, setLocalValue] = useState("");

	useEffect(() => {
		setLocalValue(value ? numeral(value).format() : "");
	}, [value]);

	const localOnChange = (e) => {
		const inputValue = e.target.value ? e.target.value : "";
		setLocalValue(inputValue);
	};

	const localOnBlur = (e) => {
		// fix input
		const formattedValue = numeral(localValue).format();

		setLocalValue(formattedValue);

		// revert the fixed value to a js float
		const unformattedValue = numeral(formattedValue).value();

		onChange({
			target: {
				name: name,
				value: unformattedValue, // parse this from text to float
			},
		});
	};

	return <Input type="text" onChange={localOnChange} name={name} value={localValue} className={className} onBlur={localOnBlur}></Input>;
};

export default NumberField;
