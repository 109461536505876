import { Formik } from "formik";
import { StepOneFormItems } from "pages/incidents/components/IncidentForm/StepOneFormItems";
import { Alert, Button, Col, Form, FormGroup, Row } from "reactstrap";
import { step1ValidationSchema } from "../../components/schema";

const StepOne = ({ initialValues, onSubmit }) => {
	return (
		<Formik
			initialValues={initialValues}
			validateOnBlur={false}
			validateOnChange={false}			
			onSubmit={(values) => {
				// same shape as initial values
				// gå vidare till steptwo
				onSubmit(values);
			}}
			validationSchema={step1ValidationSchema}
		>
			
			{({ errors, values, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
				<Form onSubmit={handleSubmit} noValidate>
					{Object.keys(errors).length > 0 && <Alert color="danger">Du har inte fyllt i alla obligatoriska fält. Frågor markerade med röd text måste fyllas i.</Alert>}

					<StepOneFormItems
						values={values}
						errors={errors}
						touched={touched}
						handleBlur={handleBlur}
						handleChange={handleChange}
						setFieldValue={setFieldValue}
					/>
					<FormGroup>
						<Row>
							<Col xs={12} className="d-flex justify-content-end">
								<Button color="primary" type="submit">
									Gå vidare
								</Button>
							</Col>
						</Row>
					</FormGroup>
				</Form>
			)}
		</Formik>
	);
};
export default StepOne;
