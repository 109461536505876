import FormatCurrency from "components/FormatCurrency";
import HilightCard from "components/HilightCard";
import { Row, Col } from "reactstrap";

const InfoCardSite = ({ result }) => {

	const finished = result.status === 2;
	const data = result.data;

	return (
		<Row>
			<Col sm={6} className="p-4">
				<div className="d-flex justify-content-between mb-2">
					<div>
						<label>Preliminär kostnad</label>
					</div>
					<div className="d-flex justify-content-end">
						<span className="small-value">{ !finished ? "-" : <FormatCurrency value={data.preliminaryCost} />}</span>
					</div>
				</div>
				<div className="d-flex justify-content-between mb-2">
					<div>
						<label>Motpartskostnad</label>
					</div>
					<div className="d-flex justify-content-end">
						<span className="small-value">{ !finished ? "-" : <FormatCurrency value={data.counterpartCost} />}</span>
					</div>
				</div>
				<div className="d-flex justify-content-between mb-2">
					<div>
						<label>Total kostnad</label>
					</div>
					<div className="d-flex justify-content-end">
						<span className="small-value">{ !finished ? "-" : <FormatCurrency value={data.totalCost} />}</span>
					</div>
				</div>
			</Col>
			<Col sm={6}>
				<HilightCard>
                <div className="d-flex justify-content-between">                    
					<div>
						<label>Krock</label>
						<div className="medium-value">{!finished ? "-" : data.crashAmount}</div>
					</div>
                    <div>
						<label>Stöld/inbrott</label>
						<div className="medium-value">{!finished ? "-" : data.theftAmount}</div>
					</div>
                    <div>
						<label>Brand</label>
						<div className="medium-value">{!finished ? "-" : data.fireAmount}</div>
					</div>
				</div>

                </HilightCard>
			</Col>
		</Row>
	);
};

export default InfoCardSite;
