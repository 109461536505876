import React from "react";
import { Nav, NavbarBrand } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { AuthMenuItems } from "./api-authorization/MenuItem";
import logo from "../assets/logo.png";
import Thlogo from "../assets/Trygg-Hansa_Logo.svg";

const Sidebar = ({ isOpen, toggle }) => (
	<div className={classNames("sidebar", "bg-dark", { "is-open": isOpen })}>
		<div className="sidebar-header d-flex justify-between">
			<NavbarBrand tag={Link} to="/" className="nav-link my-2">
				<img src={logo} alt="logo" width="250" className="img-fluid" />
			</NavbarBrand>
			<span color="info" onClick={toggle} style={{ color: "#fff" }}>
				&times;
			</span>
		</div>
		<div className="side-menu">
			<Nav vertical className="list-unstyled pb-3">
				<AuthMenuItems onItemClick={toggle} />
			</Nav>
		</div>
		<img
			src={Thlogo}
			alt="logo"
			width="125"
			className="img-fluid mx-auto d-block"
		/>
	</div>
);

export default Sidebar;
