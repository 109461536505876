
const translateIncidentCategory = (value) => {

	const categoryLowered = value ? value.toLowerCase() : "";

	return categoryLowered === "crash" ? "Krock" : categoryLowered === "fire" ? "Brand" : categoryLowered === "theft" ? "Stöld" : "";
};

export default translateIncidentCategory;

