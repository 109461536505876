import Panel from "components/Panel";
import React, { useEffect, useState } from "react";
import ReviewAndSubmit from "./components/ReviewAndSubmit";
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import { initialValuesStep1, initialValuesStep2 } from "../components/schema";
import { useApi } from "components/useApi3";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";

const CreateIncident = () => {
	// tre tabbar med två olika formulär som har varsin validering, sen en granskasida
	// när det är dags att spara, kombinera dessa två formulärs state
	// man sparar en IncidentFormDto, som är step1 och 2 kombinerat

	const [step, setStep] = useState(1);
	const [createResult, createApi] = useApi();
	// when a form is submitted save the values to this state, and if the form is revisited it gets repopulated with this state
	const [step1Values, setStep1Values] = useState(initialValuesStep1);
	const [step2Values, setStep2Values] = useState(initialValuesStep2);

	const history = useHistory();

	useEffect(() => {
		if (createResult.status === 2) {
			history.push("/incidents/mine");
		}

	}, [createResult, history]);

	return (
		<>
			{step === 1 && (
				<div className="d-flex justify-content-center m-2">
					<strong>1. Skadeärende &gt;</strong>&nbsp;2. Beskrivning &gt; 3. Granska
				</div>
			)}
			{step === 2 && (
				<div className="d-flex justify-content-center m-2">
					1. Skadeärende &gt;&nbsp;<strong>2. Beskrivning &gt; </strong>&nbsp;3. Granska
				</div>
			)}
			{step === 3 && (
				<div className="d-flex justify-content-center m-2">
					1. Skadeärende &gt;&nbsp;2. Beskrivning &gt;&nbsp;<strong>3. Granska</strong>
				</div>
			)}
			<Panel>
				<div className="incident-form" id="topOfForm">
					{step === 1 && (
						<StepOne
							initialValues={step1Values}
							onSubmit={(values) => {
								setStep1Values(values);
								setStep(2);
							}}
						/>
					)}
					{step === 2 && (
						<StepTwo
							insuranceCategory={step1Values.insuranceCategory}
							initialValues={step2Values}
							onBack={() => {
								setStep(1);
							}}
							onSubmit={(values) => {
								setStep2Values(values);
								setStep(3);
							}}
						/>
					)}
					{step === 3 && (
						<ReviewAndSubmit
							values={{ ...step1Values, ...step2Values }}
							onSubmit={() => {
								// post data to api and wait for the error message
								const saveMe = {
									...step1Values,
									...step2Values,
									numberOfPassengers: parseInt(step2Values.numberOfPassengers),
									mileage: parseInt(step2Values.mileage),
									incidentDate: step1Values.incidentDate.toISOString(),
									// followUpTalkDate: .followUpTalkDate !== "" ? state.followUpTalkDate.toISOString() : null,
									// handlingDoneDate: state.handlingDoneDate !== "" ? state.handlingDoneDate.toISOString() : null,
								};

								createApi.post("api/incident/incidents", saveMe);
							}}
							createResult={createResult}
							onBack={() => {
								setStep(2);
							}}
						/>
					)}
				</div>
				{ createResult.status > 2 && <Alert color="danger">Ett fel inträffade. Kunde inte spara ärendet.</Alert> }
				{ createResult.status === 1 && <Alert color="info">Sparar ärendet..</Alert>}
			</Panel>
		</>
	);
};

export default CreateIncident;
