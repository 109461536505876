import { useState, useMemo } from "react";
import { usePagination, useTable, useAsyncDebounce, useGlobalFilter, useSortBy } from "react-table";
import { Button, ButtonGroup, Col, Input, Row } from "reactstrap";
import LoadingSpinner from "./LoadingSpinner";
import { matchSorter } from "match-sorter";
import useTableExport from "./useTableExport";
import { LoadingIcon } from "./Icons";

const GlobalFilter = ({ globalFilter, setGlobalFilter, disabled }) => {
	const [value, setValue] = useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<Input
			value={value || ""}
			onChange={(e) => {
				setValue(e.target.value);
				onChange(e.target.value);
			}}
			className="mb-4"
			disabled={disabled}
			placeholder="Sök.."
			autoComplete="off"
			name="searchInput"
		></Input>
	);
};

function fuzzyTextFilterFn(rows, id, filterValue) {
	return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

// Define a default UI for filtering
const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
	const count = preFilteredRows.length;

	return (
		<input
			value={filterValue || ""}
			onChange={(e) => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
			placeholder={`Search ${count} records...`}
		/>
	);
};

const ReactTable = ({ columns, result, rowData, externalFilters, hideSearch, hideColumnHeader, hideExport }) => {
	// externalFilters är för att kunna rendera uppe till höger

	const [exportTableState, exportTable] = useTableExport();
	// de flesta fallen skickar man in ett "result" från useApi där raderna finns i result.data
	// ibland vill man dock skicka in result separat och rows i en understruktur i result. t.ex. result.data.incidents
	// så anger man rows så försöker vi använda rows, annars result.data
	// så ange ALLTID columns, result. men ibland rows från samma result
	// eftersom "laddar" feedback är inbyggt i table
	const data = useMemo(
		() => (rowData ? (result.status === 2 ? rowData : []) : result.status === 2 ? result.data : []),

		[result.status, result.data, rowData]
	);

	const filterTypes = useMemo(
		() => ({
			fuzzyText: fuzzyTextFilterFn,

			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];

					return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
				});
			},
		}),
		[]
	);
	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,	
		rows,	
		prepareRow,
		gotoPage,
		canPreviousPage,
		previousPage,
		nextPage,
		canNextPage,
		pageCount,
		visibleColumns,
		pageOptions,
		setGlobalFilter,
		state: { pageIndex, globalFilter },
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			initialState: { pageIndex: 0, pageSize: 50 },
			//manualPagination: true, // Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			//pageCount: controlledPageCount,
			// autoResetFilters: false,
			// autoResetSortBy: false,
			// manualFilters: true,
			// manualSortBy: true
			autoResetPage: false,
			autoResetExpanded: false,
			autoResetGroupBy: false,
			autoResetSelectedRows: false,
			autoResetSortBy: false,
			autoResetFilters: false,
			autoResetRowState: false,
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	return (
		<>
			<div className="d-sm-flex justify-content-between w-100">
				<div>
					<Row>
						<Col>
							<div
								style={{
									minWidth: "300px",
								}}
							>
								{!hideSearch && <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} disabled={result.status !== 2} />}
							</div>
						</Col>
						<Col>{!hideExport && <Button className="btn-form-input" color="white" disabled={result.status !== 2 || exportTableState === 1} onClick={() => {
							exportTable(columns,rows,prepareRow);
						}}>{exportTableState === 1 ? <><LoadingIcon /> Exporterar..</> : "Exportera"}</Button>}</Col>
					</Row>
				</div>

				<div className="d-flex justify-content-end">{externalFilters}</div>
			</div>

			<table className="table table-grid d-none d-sm-table" {...getTableProps()}>
				{!hideColumnHeader && (
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render("Header")}
										<span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
									</th>
								))}
							</tr>
						))}
					</thead>
				)}

				{result.status === 1 ? (
					<tbody>
						<tr>
							<td colSpan={visibleColumns.length}>
								<div style={{ height: "200px", width: "100%" }}></div>
								<LoadingSpinner />
								<div style={{ height: "200px", width: "100%" }}></div>
							</td>
						</tr>
					</tbody>
				) : (
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
									})}
								</tr>
							);
						})}
					</tbody>
				)}
			</table>
			<table className="table table-grid d-table d-sm-none" {...getTableProps()}>
				{result.status === 1 ? (
					<tbody>
						<tr>
							<td colSpan={visibleColumns.length}>
								<div style={{ height: "200px", width: "100%" }}></div>
								<LoadingSpinner />
								<div style={{ height: "200px", width: "100%" }}></div>
							</td>
						</tr>
					</tbody>
				) : (
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									<td>
										{row.cells.length > 0 && (
											<div>
												<h1>{row.cells[0].render("Cell")}</h1>
											</div>
										)}
										{row.cells.length > 1 && <div>{row.cells[1].render("Cell")}</div>}
										{row.cells.length > 2 && <div>{row.cells[2].render("Cell")}</div>}
										{row.cells.length > 3 && <div>{row.cells[row.cells.length - 1].render("Cell")}</div>}
									</td>
								</tr>
							);
						})}
					</tbody>
				)}
			</table>
			{pageOptions.length > 1 && (
				<>
					<div className="d-sm-flex justify-content-center d-none">
						<ButtonGroup>
							<Button color="white" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
								{"<<"}
							</Button>
							<Button color="white" onClick={() => previousPage()} disabled={!canPreviousPage}>
								{"<"}
							</Button>
							<Button color="white">
								<span>
									Sida{" "}
									<strong>
										{pageIndex + 1} av {pageOptions.length}
									</strong>
								</span>
							</Button>
							<Button color="white" onClick={() => nextPage()} disabled={!canNextPage}>
								{">"}
							</Button>
							<Button color="white" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
								{">>"}
							</Button>
						</ButtonGroup>
					</div>
					<div className="d-flex justify-content-center d-sm-none">
						<ButtonGroup>
							<Button color="white" onClick={() => previousPage()} disabled={!canPreviousPage}>
								{"<"}
							</Button>
							<Button color="white">
								{pageIndex + 1}/{pageOptions.length}
							</Button>
							<Button color="white" onClick={() => nextPage()} disabled={!canNextPage}>
								{">"}
							</Button>
						</ButtonGroup>
					</div>
				</>
			)}
		</>
	);
};

export default ReactTable;
