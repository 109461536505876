import FormatCurrency from "components/FormatCurrency";
import HilightCard from "components/HilightCard";
import { ArrowRightIcon } from "components/Icons";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

const InfoCardUser = ({ result }) => {
	const finished = result.status === 2;
	const data = result.data;

	return (
		<HilightCard>
			<Row>
				<Col xs={12} className="mb-4">
					<div className="d-flex justify-content-center">
						<label>Din totala skadekostnad</label>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={6}>
					<div className="d-flex justify-content-center">
						<div className="large-value">{!finished ? "-" : <FormatCurrency value={data.userTotalCost} />}</div>
					</div>
					<div className="d-flex justify-content-center">
						{!finished ? (
							"-"
						) : (
							<div className="medium-value">
								{Math.abs(data.userComparsionPercentage)}
								{data.userComparsionPercentage <= 0 ? "% lägre än genomsnittet" : "% högre än genomsnittet"}
							</div>
						)}
					</div>
				</Col>
				<Col xs={6}>
					<div className="d-flex justify-content-between mb-2">
						<label>Krock</label>
						<span className="small-value">{!finished ? "-" : data.userCrashAmount}</span>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<label>Stöld/inbrott</label>
						<span className="small-value">{!finished ? "-" : data.userTheftAmount}</span>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<label>Brand</label>
						<span className="small-value">{!finished ? "-" : data.userFireAmount}</span>
					</div>
				</Col>
			</Row>
			<Row>
				<Col xs={12} className="d-flex justify-content-center mt-4">
					<Link className="large-link" to="/incidents/followup">
						Visa mer <ArrowRightIcon />
					</Link>
				</Col>
			</Row>
		</HilightCard>
	);
};

export default InfoCardUser;
