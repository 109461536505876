import { FormGroup, Label, Input, Alert } from "reactstrap";

const Form = ({ values, errors, touched, handleBlur, handleChange, setFieldValue, isEdit, managerList, sitesList }) => {
	return (
		<>
			<FormGroup>
				<Label for="name">Namn</Label>
				<Input
					className={errors.name ? "is-invalid" : ""}
					autoComplete="off"
					name="name"
					value={values.name}
					onChange={handleChange}
					onBlur={handleBlur}
				></Input>
				{errors.name && touched.name && <span className="text-danger">{errors.name}</span>}
			</FormGroup>
			<FormGroup>
				<Label for="name">Personnummer</Label>
				<Input
					className={errors.personalIdentityNumber ? "is-invalid" : ""}
					autoComplete="off"
					name="personalIdentityNumber"
					value={values.personalIdentityNumber}
					onChange={handleChange}
					onBlur={handleBlur}
				></Input>
				{errors.personalIdentityNumber && touched.personalIdentityNumber && <span className="text-danger">{errors.personalIdentityNumber}</span>}
			</FormGroup>
			<FormGroup>
				<Label for="name">E-post</Label>
				<Input
					className={errors.email ? "is-invalid" : ""}
					autoComplete="off"
					name="email"
					value={values.email}
					onChange={handleChange}
					onBlur={handleBlur}
				></Input>
				{errors.email && touched.email && <span className="text-danger">{errors.email}</span>}
			</FormGroup>
			<FormGroup>
				<Label for="name">Mobilnummer</Label>
				<Input
					className={errors.mobilePhone ? "is-invalid" : ""}
					autoComplete="off"
					name="mobilePhone"
					value={values.mobilePhone}
					onChange={handleChange}
					onBlur={handleBlur}
				></Input>
				{errors.mobilePhone && touched.mobilePhone && <span className="text-danger">{errors.mobilePhone}</span>}
			</FormGroup>
			<FormGroup>
				<Label>Ärendehanterare</Label>
				<Input
					type="select"
					name="managerId"
					id="managerId"
					disabled={managerList.length === 0}
					value={values.managerId}
					onChange={handleChange}
					onBlur={handleBlur}
				>
					<option value="">-- Välj Ärendehanterare --</option>
					{managerList.map((item) => {
						return (
							<option key={item.id} value={item.id}>
								{item.name}
							</option>
						);
					})}
				</Input>
			</FormGroup>
			<FormGroup>
				<Label>Ort</Label>
				<Input
					type="select"
					name="siteId"
					id="siteId"
					disabled={sitesList.length === 0}
					value={values.siteId}
					onChange={handleChange}
					onBlur={handleBlur}
				>
					<option value="">-- Välj Ort --</option>
					{sitesList.map((item) => {
						return (
							<option key={item.id} value={item.id}>
								{item.name}
							</option>
						);
					})}
				</Input>
				{errors.siteId && touched.siteId && <span className="text-danger">{errors.siteId}</span>}

			</FormGroup>
			<FormGroup>
				<Label>Roller</Label>
				<FormGroup check>
					<Label check>
						<Input
							type="checkbox"
							name="incidentReporter"
							checked={values.roles.indexOf("IncidentReporter") !== -1}
							onChange={(e) => {
								const isChecked = e.target.checked;
								const newRoles = values.roles.filter((x) => x !== "IncidentReporter");
								if (isChecked) {
									newRoles.push("IncidentReporter");
								}

								setFieldValue("roles", newRoles);
							}}
						/>
						Skadeanmälare
					</Label>
				</FormGroup>				
				<FormGroup check>
					<Label check>
						<Input type="checkbox" name="incidentHandler"
						checked={values.roles.indexOf("IncidentHandler") !== -1}
						onChange={(e) => {
							const isChecked = e.target.checked;
							const newRoles = values.roles.filter((x) => x !== "IncidentHandler");
							if (isChecked) {
								newRoles.push("IncidentHandler");
							}

							setFieldValue("roles", newRoles);
						}}
						
						/>
						Skadehanterare
					</Label>
				</FormGroup>
				<FormGroup check>
					<Label check>
						<Input type="checkbox" name="companyAdmin"
						checked={values.roles.indexOf("CompanyAdmin") !== -1}
						onChange={(e) => {
							const isChecked = e.target.checked;
							const newRoles = values.roles.filter((x) => x !== "CompanyAdmin");
							if (isChecked) {
								newRoles.push("CompanyAdmin");
							}

							setFieldValue("roles", newRoles);
						}}
						/>
						Bolagsadmin
					</Label>
				</FormGroup>
				{errors.roles && touched.roles && <span className="text-danger">{errors.roles}</span>}

			</FormGroup>
			{!isEdit && (
				<FormGroup>
					<Alert color="info">
						Temporärt lösenord: <strong>{values.tempPassword}</strong>, syns endast här vid skapandet av användaren.
					</Alert>
				</FormGroup>
			)}
		</>
	);
};
export default Form;
