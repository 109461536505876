import React from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { Row, Col } from 'reactstrap';
import "./SplitDatetime.css";

const SplitDatetime = (props) => {
    const { onChange, errors, value } = props;

    const [dateValue, setDateValue] = React.useState(value);
    const [timeValue, setTimeValue] = React.useState(value);
    const onChangeDate = (e) =>
    {
        setDateValue(e);
        onChange(moment(moment(e, 'YYYY-MM-DD').format("YYYY-MM-DD") + " " + moment(timeValue, 'HH:mm').format("HH:mm")));
    }
    
    const onChangeTime = (e) =>
    {
        setTimeValue(e);
        onChange(moment(moment(dateValue, 'YYYY-MM-DD').format("YYYY-MM-DD") + " " + moment(e, 'HH:mm').format("HH:mm")));
    }


    return <Row>
        <Col lg={8} xs={7}>
        <Datetime
        className="incidentDate-datePicker"
        onChange={onChangeDate}  
        timeFormat={false}
        inputProps={{ placeholder: "Datum" , className: errors.incidentDate ? 'form-control is-invalid' : 'form-control' }}
        value={dateValue}
        />
        </Col>
        <Col lg={4} xs={5}>
        <Datetime
        className="incidentDate-timePicker"
        onChange={onChangeTime}  
        timeFormat={true}
        dateFormat={false}
        inputProps={{ placeholder: "Tid" , className: errors.incidentDate ? 'form-control is-invalid' : 'form-control' }}
        value={timeValue}
        />
        </Col>
       
        </Row>
}
export default SplitDatetime;
