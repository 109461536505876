import { Col, Row } from "reactstrap";

const PageHeader = ({title, subtitle, commands}) => {
    return <>
    <Row className="header">
        <Col sm={12}>
            <div className="d-flex justify-content-between">
                <div>
                    <h1>{title}</h1>
                    <span>{subtitle}</span>
                </div>
                {commands}
            </div>
        </Col>
    </Row>
    </>
}
export default PageHeader;
