import React, { useState, useRef, useEffect } from "react";
import { Button, ButtonGroup, Alert, ListGroup, ListGroupItem } from "reactstrap";
import { useApi } from "components/useApi3";

const FileUpload = ({ attachments, onSuccessUpload, onRemoveImageItem }) => {

    const [uploadResult, uploadApi] = useApi();
	const [downloadResult, downloadApi] = useApi();

	const [file, setFile] = useState(null);

	const fileInput = useRef();

	const onFileUploadChange = (e) => {
		// console.log(e);
		e.preventDefault();
		setFile(e.target.files[0]);
	};

	const triggerInputFile = (e) => {
		fileInput.current.click();
	};

	useEffect(() => {
        if (file) {

            uploadApi.file("api/incident/uploadimage", file);
        }
	}, [file, uploadApi]);

	useEffect(() => {
		if (uploadResult.status === 2) {
            console.log(uploadResult.data);
			onSuccessUpload(uploadResult.data);
			setFile(null);
            uploadApi.reset();
		}
		if (uploadResult.status > 2) {
			setFile(null);
		}
	}, [uploadResult, onSuccessUpload, uploadApi]);

	const downloadFile = (name) => {

        downloadApi.getBlob('api/incident/downloadimage?name='.concat(name), name);

	};

	return (
		<ListGroup>
			<ListGroupItem>
				<input className="d-none" type="file" name="file" id="exampleFile" ref={fileInput} onChange={onFileUploadChange}></input>
				<ButtonGroup className="mb-2">
					<Button color="white" onClick={triggerInputFile} disabled={uploadResult.status === 1}>
						Välj fil {file ? <span> {uploadResult.status === 1 ? <span>(Lägger till {file.name})</span> : null}</span> : null}
					</Button>
				</ButtonGroup>
				{uploadResult.status > 2 ? <Alert color="warning">Det gick inte att lägga till filen.</Alert> : null}
			</ListGroupItem>
			{attachments.length === 0 ? (
				<ListGroupItem>
					<i>För att ladda upp foton klicka Välj fil</i>
				</ListGroupItem>
			) : null}
			{attachments && attachments.length > 0 && attachments.map((item, key) => {
				return (
					<ListGroupItem key={key}>
						<ButtonGroup>
							<Button
								color="white"
								onClick={() => {
									onRemoveImageItem(item.name);
								}}
							>
								Ta bort
							</Button>
							<Button
								disabled={downloadResult.status === 1}
								color="white"
								onClick={() => {
									downloadFile(item.name);
								}}
							>
								Ladda ned
							</Button>
						</ButtonGroup>{" "}
						{decodeURI(item.shortName).replace("+", " ")}{" "}
					</ListGroupItem>
				);
			})}
		</ListGroup>
	);
};

export default FileUpload;
