import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { ArrowRightIcon } from "../components/Icons";
import { useApi } from "components/useApi3";

const Hero = ({ isIncidentReporter }) => {
	const [result, resultApi] = useApi();

	useEffect(() => {

		resultApi.get("api/system/users/minename");
	}, [resultApi]);

	return (
		<div className="hero">
			<h2>
				Välkommen,
				<br />
				<small className="text-muted">{result.status === 2 ? result.data : "-"}</small>
			</h2>
			{isIncidentReporter ? (
				<div className="text-center reporter-section mt-3">
					<p className="font-weight-bold mb-2">Vill du anmäla en skada?</p>
					<Link to="/incidents/create">
						<Button color="primary" type="button">
							Rapportera incident
							<ArrowRightIcon />
						</Button>
					</Link>
				</div>
			) : null}
		</div>
	);
};

export default Hero;
