import Panel from "components/Panel";
import PageHeader from "components/PageHeader";
import { useApi } from "components/useApi3";
import moment from "moment";
import { useEffect } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import { Col, FormGroup, Row } from "reactstrap";
import DateFormat from "components/DateFormat";
import FormatCurrency from "components/FormatCurrency";
// two tabbar, en för "hantera ärende" och en för "skadeinformation"
// det man hämtar och redigerar är en IncidentHandleFormDto som är step1+step2+handle

const PageLabel = ({ result }) => {
	const data =
		result.status === 2
			? result.data
			: {
					insuranceCategory: "",
					location: "",
					crashCategory: "",
					theftItems: "",
					fireType: "",
					vehicleDisplayName: "",
					incidentDate: "",
			  };
	const title =
		data.insuranceCategory === "CRASH"
			? data.crashCategory
			: data.insuranceCategory === "THEFT"
			? data.theftItems
			: data.insuranceCategory === "FIRE"
			? data.fireType
			: "".concat(" - ").concat(data.vehicleDisplayName);
	const subtitle = data.location.concat(" | ").concat(data.incidentDate ? moment(data.incidentDate).format("YYYY-MM-DD") : "");

	return <PageHeader title={title} subtitle={subtitle} />;
};

const LabelValue = ({ label, value }) => {
	return (
		<FormGroup>
			<label>{label}</label>
			<div>{value ? value : "-"}</div>
		</FormGroup>
	);
};
const CounterPartList = ({ items }) => {
	return items && items.length > 0 ? (
		<Row>
			{items.map((item, key) => {
				return (
					<Col key={key} sm={4}>
						<Panel>
							<div className="small-label">Namn</div>
							<h1>{item.name}</h1>
							<div className="small-label">Regnummer</div>
							<div>{item.registrationNumber}</div>
							<div className="small-label">Person- eller organisationsnummer</div>
							<div>{item.personalOrOrganisationNumber}</div>
							<div className="small-label">Telefonnummer</div>
							<div>{item.telephoneNumber}</div>
						</Panel>
					</Col>
				);
			})}
		</Row>
	) : (
		"-"
	);
};
const IncidentDetails = ({ item }) => {
	const insuranceLabel =
		item.insuranceCategory === "CRASH"
			? "Krock, trafikolycka eller skada på fordon"
			: item.insuranceCategory === "THEFT"
			? "Stöld eller inbrott"
			: item.insuranceCategory === "FIRE"
			? "Brand"
			: null;

	return (
		<>
			<Row>
				<Col sm={4}>
					<LabelValue label="Ärende" value={insuranceLabel} />
				</Col>
				<Col sm={4}>
					<LabelValue label="Plats" value={item.location} />
				</Col>
				<Col sm={4}>
					<LabelValue label="Händelsedatum" value={<DateFormat>{item.incidentDate}</DateFormat>} />
				</Col>
			</Row>
			<Row>
				<Col sm={4}>
					<LabelValue label="Typ av skada" value={item.crashCategory} />
				</Col>
				<Col sm={4}>
					<LabelValue label="Fordon" value={item.vehicleDisplayName} />
				</Col>
				<Col sm={4}>
					<LabelValue label="Mätarställning" value={item.mileage} />
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<LabelValue label="Skadan inträffad" value={item.damageLocationCategory.join(", ")} />
				</Col>
			</Row>
			{item.crashCategory === "Kollision" && (
				<>
					<Row>
						<Col sm={12}>
							<LabelValue label="Möteskollision" value={item.isTrafficCollision ? "Ja" : "Nej"} />
						</Col>
					</Row>
					<Row>
						<Col sm={12}>
							<LabelValue label="Vägens ungefärliga bredd" value={item.estimatedRoadWidth} />
						</Col>
					</Row>
				</>
			)}
			<hr />
			<Row>
				<Col sm={4}>
					<LabelValue label="Datum för uppföljningssamtal" value={<DateFormat>{item.followUpTalkDate}</DateFormat>} />
				</Col>
				<Col sm={4}>
					<LabelValue label="Reglering klar, datum" value={<DateFormat>{item.handlingDoneDate}</DateFormat>} />
				</Col>
				<Col sm={4}>
					<LabelValue label="Skadenummer" value={item.damageNumber} />
				</Col>
			</Row>
			<Row>
				<Col sm={4}>
					<LabelValue label="Bärgning" value={item.towingCompany} />
				</Col>
			</Row>
			<hr />
			<Row>
				<Col sm={12}>
					<LabelValue label="Händelsebeskrivning" value={item.descriptionOfEvents} />
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<LabelValue label="Antal passagerare" value={item.numberOfPassengers} />
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<LabelValue label="Kontaktuppgifter, motpart" value={<CounterPartList items={item.counterPartContactInformations} />} />
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<LabelValue label="Vittnen" value={item.witnesses} />
				</Col>
			</Row>
			<hr />
			<Row>
				<Col sm={12}>
					<LabelValue
						label="Övrigt"
						value={
							<ul>
								{item.policeContacted ? <li className="value">Polis inkopplad: Ja</li> : null}
								{item.emergencyServiceContacted ? <li className="value">Räddningstjänst inkopplad: Ja</li> : null}
								{item.bloodTest ? <li className="value">Blodprov har tagits: Ja</li> : null}
								{item.breathTest ? <li className="value">Utandningsprov har tagits: Ja</li> : null}
								{item.replacementVehicleHired ? <li className="value">Ersättningsfordon har anlitats: Ja</li> : null}
								{item.supplementedForInsuranceCompany ? <li className="value">Kompletterad till försäkringsbolag: Ja</li> : null}
							</ul>
						}
					/>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col sm={4}>
					<LabelValue label="Beräknad kostnad för vållad skada" value={<FormatCurrency value={item.estimatedCostForInjury} />} />
				</Col>
				<Col sm={4}>
					<LabelValue label="Ersättningsbelopp" alue={<FormatCurrency value={item.compensationAmount} />} />
				</Col>
				<Col sm={4}>
					<LabelValue label="Motpartskostnad" value={<FormatCurrency value={item.counterpartCost} />} />
				</Col>
			</Row>
			<hr />
			<Row>
				<Col sm={12}>
					<LabelValue label="Materiella skador (på annat än fordon)" value={item.materialDamages} />
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<LabelValue label="Synliga skador på fordon eller andra föremål?" value={item.visibleDamages ? "Ja" : "Nej"} />
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<LabelValue label="Beskrivning synliga skador" value={item.visibleDamagesDescription} />
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<LabelValue label="Personskada?" value={item.personalDamages ? "Ja" : "Nej"} />
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<LabelValue label="Beskrivning personskador" value={item.personalDamagesDescription} />
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<LabelValue label="Fordonet är bärgat?" value={item.isVehicleTowed ? "Ja" : "Nej"} />
				</Col>
			</Row>
			{item.isVehicleTowed && (
				<Row>
					<Col sm={12}>
						<LabelValue label="Fordonet bärgades till" value={item.materialDamages} />
					</Col>
				</Row>
			)}
		</>
	);
};

const IncidentView = ({ match }) => {
	const [itemResult, itemApi] = useApi();

	useEffect(() => {
		itemApi.get("api/incident/incidents/".concat(match.params.id));
	}, [itemApi, match]);

	return (
		<>
			<PageLabel result={itemResult} />
			<Panel>{itemResult.status === 1 ? <LoadingSpinner /> : itemResult.status === 2 ? <IncidentDetails item={itemResult.data} /> : null}</Panel>
		</>
	);
};
export default IncidentView;
