import React from "react";
import { Progress } from "reactstrap";

const IncidentProgress = ({ status }) => {
	const statusLowered = status ? status.toLowerCase() : "";

	return statusLowered === "open" ? (
		<Progress
			style={{
				minWidth: "200px",
			}}
			color="success"
			value={33}
		/>
	) : statusLowered === "sent" ? (
		<Progress
			style={{
				minWidth: "200px",
			}}
			color="success"
			value={66}
		/>
	) : statusLowered === "completed" ? (
		<Progress
			style={{
				minWidth: "200px",
			}}
			color="success"
			value={100}
		/>
	) : null;
};

export default IncidentProgress;
