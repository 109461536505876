import React, { useState } from "react";
import { Formik } from "formik";
import { StepOneFormItems } from "pages/incidents/components/IncidentForm/StepOneFormItems";
import { StepTwoFormItems } from "pages/incidents/components/IncidentForm/StepTwoFormItems";
import { HandleIncidentFormInputs } from "pages/incidents/components/IncidentForm/HandleIncidentFormInputs";
import classnames from "classnames";

import { Alert, Button, Col, Form, FormGroup, Nav, NavItem, Row, NavLink } from "reactstrap";
import { allSchemasCombined } from "../../components/schema";
import { ArrowRightIcon } from "components/Icons";

const EditForm = ({ vehicles, vehiclesStatus, initialValues, onSubmit, onSubmitSend }) => {
	const [tab, setTab] = useState(0);
	let submitAction = "";

	return (
		<>
			<Nav tabs>
				<NavItem>
					<NavLink
						className={classnames({ active: tab === 0 })}
						onClick={() => {
							setTab(0);
						}}
					>
						Hantera ärende
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: tab === 1 })}
						onClick={() => {
							setTab(1);
						}}
					>
						Skadeinformation
					</NavLink>
				</NavItem>
			</Nav>
			<hr />
			<Formik
				initialValues={initialValues}
				validateOnBlur={false}
				validateOnChange={false}
				onSubmit={(values) => {

					//console.log(submitAction);
					if (submitAction === "complete")
					{
						// sätt status till Completed
						values.status = "Completed";
						// sen spara
						onSubmit(values);
					}
					if (submitAction === "send")
					{
						// spara och sen skicka till history.push(incidents/send) vyn
						onSubmitSend(values);

					} else {
						// vanlig spara
						onSubmit(values);

					}
					
				}}
				validationSchema={allSchemasCombined}
			>
				{({ errors, values, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
					<Form onSubmit={handleSubmit} noValidate>
						{Object.keys(errors).length > 0 && (
							<Alert color="danger">Du har inte fyllt i alla obligatoriska fält. Frågor markerade med röd text måste fyllas i.</Alert>
						)}
						{tab === 1 ? (
							<>
								<StepOneFormItems
									editMode
									values={values}
									errors={errors}
									touched={touched}
									handleBlur={handleBlur}
									handleChange={handleChange}
									setFieldValue={setFieldValue}
								/>
								<StepTwoFormItems
									insuranceCategory={values.insuranceCategory}
									vehiclesStatus={vehiclesStatus}
									vehicles={vehicles}
									values={values}
									errors={errors}
									touched={touched}
									handleBlur={handleBlur}
									handleChange={handleChange}
									setFieldValue={setFieldValue}
								/>
							</>
						) : (
							<>
								<HandleIncidentFormInputs
									values={values}
									errors={errors}
									touched={touched}
									handleBlur={handleBlur}
									handleChange={handleChange}
									setFieldValue={setFieldValue}
								/>
							</>
						)}

						<FormGroup>
							<Row>
								<Col xs={12} className="d-flex justify-content-end">
									{values.status.toLowerCase() === "open" && (
										<Button
											onClick={() => {
												submitAction = "send";
												handleSubmit();
											}}
											color="primary"
											type="button"
											className="me-2"
										>
											Skicka in <ArrowRightIcon />
										</Button>
									)}
									{values.status.toLowerCase() === "sent" && (
										<Button
											onClick={() => {
												submitAction = "complete";
												handleSubmit();
											}}
											color="primary"
											type="button"
											className="me-2"
										>
											Avsluta
										</Button>
									)}

									<Button
										onClick={() => {
											submitAction = "save";
											handleSubmit();
										}}
										color="primary"
										type="button"
									>
										Spara <ArrowRightIcon />
									</Button>
								</Col>
							</Row>
						</FormGroup>
					</Form>
				)}
			</Formik>
		</>
	);
};
export default EditForm;
