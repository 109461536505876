import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import './react-autosuggest.css';

const SelectVehicle = ({ vehicles, vehicleId, setVehicleId, className, vehiclesStatus }) => {

    const [suggestions, setSuggestions] = useState([]);
    const [value, setValue] = useState("");
    
    useEffect(() => {
      var findVehicle = vehicles.find(item => item.id === vehicleId);
      if (findVehicle)
        setValue(findVehicle.fullName);

    }, [vehicleId, vehicles])
        
    const vehicleNames = vehicles.map(item => item.fullName);
      
    function getSuggestions(value) {
        return vehicleNames.filter(searchStr =>
            searchStr.startsWith(value.trim()) || searchStr.toLowerCase().startsWith(value.toLowerCase().trim())
        );
      }
      function shouldRenderSuggestions() {
          return true;
      }
      return (
        <div>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsClearRequested={() => setSuggestions([])}
            onSuggestionsFetchRequested={({ value }) => {
              setValue(value);
              setSuggestions(getSuggestions(value));
            }}
            onSuggestionSelected={(_, { suggestionValue }) => {

              // console.log("Selected: " + suggestionValue);
              var findVehicle = vehicles.find(item => item.fullName === suggestionValue);
              // console.log('which is', findVehicle);
              if (findVehicle)
              {
                setVehicleId(findVehicle.id, findVehicle.fullName);
              } else {
                setVehicleId(null, null);
              }
            }

            }
            getSuggestionValue={suggestion => suggestion}
            renderSuggestion={suggestion => <span>{suggestion}</span>}
            inputProps={{
                className: className,
              placeholder: vehiclesStatus === 1 ? "Laddar lista med fordon.." : vehiclesStatus > 2 ? "Fel. Kunde inte ladda listan" : "Välj fordon",
              value: value,
              disabled: vehiclesStatus !== 2,
              onChange: (_, { newValue, method }) => {
                setValue(newValue);
              },
              onBlur:(e) => {
                
                var findVehicle = vehicles.find(item => item.fullName === e.target.value);
                // console.log('which is', findVehicle);
                if (findVehicle)
                {
                  setVehicleId(findVehicle.id,findVehicle.fullName);
                } else {
                  setVehicleId(null, null);
                }
                  
              }
            }}
            highlightFirstSuggestion={true}
            shouldRenderSuggestions={shouldRenderSuggestions}
          />
        </div>
      );

}
export default SelectVehicle;
