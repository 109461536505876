import { Formik } from "formik";
import { StepTwoFormItems } from "pages/incidents/components/IncidentForm/StepTwoFormItems";
import { useEffect } from "react";
import { Alert, Button, Col, Form, FormGroup, Row } from "reactstrap";
import { step2ValidationSchema } from "../../components/schema";
import { useApi } from "components/useApi3";

const StepTwo = ({ insuranceCategory, initialValues, onSubmit, onBack }) => {
	const [vehiclesResult, vehiclesApi] = useApi();

	useEffect(() => {
		vehiclesApi.get("api/system/vehicles/select");
	}, [vehiclesApi]);

	const vehicles = vehiclesResult.status === 2 ? vehiclesResult.data : [];
	
	return (
		<Formik
			validateOnBlur={false}
			validateOnChange={false}
			initialValues={initialValues}
			onSubmit={(values) => {
				//console.log(values);
				// same shape as initial values
				// gå vidare till steptwo
				onSubmit(values);
			}}
			validationSchema={step2ValidationSchema}
		>
			{({ errors, values, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
				<Form onSubmit={handleSubmit} noValidate>
					{Object.keys(errors).length > 0 && (
						<Alert color="danger">Du har inte fyllt i alla obligatoriska fält. Frågor markerade med röd text måste fyllas i.</Alert>
					)}
					
					<StepTwoFormItems
						insuranceCategory={insuranceCategory}
						vehiclesStatus={vehiclesResult.status}
						vehicles={vehicles}
						values={values}
						errors={errors}
						touched={touched}
						handleBlur={handleBlur}
						handleChange={handleChange}
						setFieldValue={setFieldValue}
					/>
					<FormGroup>
						<Row>
							<Col xs={12} className="d-flex justify-content-between">
								<Button
									onClick={() => {
										onBack();
									}}
									color="white"
									type="button"
								>
									Föregående
								</Button>
								<Button color="primary" type="submit">
									Granska
								</Button>
							</Col>
						</Row>
					</FormGroup>
				</Form>
			)}
		</Formik>
	);
};
export default StepTwo;
