import React, { useEffect, useMemo, useState } from "react";
import { useApi } from "components/useApi3";
import DataTable from "components/DataTable";

import PageHeader from "components/PageHeader";
import Panel from "components/Panel";
import { Button, ButtonGroup, Col, Form, FormGroup, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import AlertResultFeedback from "components/AlertResultFeedback";
import ItemForm from "./Form";
import LoadingSpinner from "components/LoadingSpinner";
import AdminNavigation from "../components/AdminNavigation";

const validationSchema = Yup.object().shape({
	name: Yup.string().required("Namn är obligatoriskt"),
	email: Yup.string().email("Ogiltig epostadress").required("E-post är obligatoriskt"),
	roles: Yup.array().min(1,"Minst en roll måste väljas"),
	siteId: Yup.number().nullable().required("Ort måste väljas")
});

function randPassword(uppercase, lowercase, numbers, nonalpha) {
	var chars = [
		"ABCDEFGHIJKLMNOPQRSTUVWXYZ",
		"abcdefghijklmnopqrstuvwxyz", // letters
		"0123456789", // numbers
		"!_@-+#%?", // either
	];

	return [uppercase, lowercase, numbers, nonalpha]
		.map(function (len, i) {
			return Array(len)
				.fill(chars[i])
				.map(function (x) {
					return x[Math.floor(Math.random() * x.length)];
				})
				.join("");
		})
		.concat()
		.join("")
		.split("")
		.sort(function () {
			return 0.5 - Math.random();
		})
		.join("");
}

const CrudPage = ({ match }) => {
	const apiBaseUrl = "api/organisation/users/";

	const [listResult, listApi] = useApi();
	const [createResult, createApi] = useApi();
	const [editItemResult, editItemApi] = useApi();
	const [editResult, editApi] = useApi();
	const [deleteResult, deleteApi] = useApi();
	const [managerListResult, managerListApi] = useApi();
	const [sitesListResult, sitesListApi] = useApi();
	const [showCreate, setShowCreate] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [deleteId, setDeleteId] = useState(null);

	useEffect(() => {
		listApi.get(apiBaseUrl);
	}, [listApi, apiBaseUrl]);

	// post skapa success
	useEffect(() => {
		if (createResult.status === 2) {
			listApi.get(apiBaseUrl);
			createApi.reset();
			setShowCreate(false);
		}
	}, [createResult.status, listApi, createApi, apiBaseUrl]);

	// post delete success
	useEffect(() => {
		if (deleteResult.status === 2) {
			listApi.get(apiBaseUrl);
			deleteApi.reset();
			setShowDelete(false);
			setShowEdit(false);
			setDeleteId(null);
		}
	}, [deleteResult.status, listApi, deleteApi, apiBaseUrl]);

	// post edit success
	useEffect(() => {
		if (editResult.status === 2) {
			listApi.get(apiBaseUrl);
			editApi.reset();
			setShowEdit(false);
		}
	}, [editResult.status, listApi, editApi, apiBaseUrl]);

	const columns = useMemo(
		() => [
			{
				accessor: "name",
				Header: "Namn",
			},
			{
				accessor: "email",
				Header: "E-post",
			},
			{
				accessor: "mobilePhone",
				Header: "Mobilnummer",
			},
			{
				accessor: "managerName",
				Header: "Ärendehanterare",
			},
			{
				accessor: "commands",
				Cell: (cell) => {
					return (
						<ButtonGroup className="float-end">
							<Button
								color="white"
								onClick={() => {
									managerListApi.get("api/organisation/users/handlers");
									sitesListApi.get("api/organisation/sites");
									editItemApi.get(apiBaseUrl.concat(cell.row.original.id));
									setShowEdit(true);
								}}
							>
								Redigera
							</Button>
						</ButtonGroup>
					);
				},
			},
		],
		[editItemApi, apiBaseUrl, managerListApi, sitesListApi]
	);

	return (
		<>
			<PageHeader
				title="Användare"
				subtitle="Administrera användare"
				commands={
					<Button
						onClick={() => {
							setShowCreate(true);
							managerListApi.get("api/organisation/users/handlers");
							sitesListApi.get("api/organisation/sites");
				}}
						color="primary"
					>
						Lägg till användare
					</Button>
				}
			/>
			<AdminNavigation />
			<Panel>
				<DataTable columns={columns} result={listResult} />
			</Panel>
			<Modal
				isOpen={showCreate}
				toggle={() => {
					setShowCreate(!showCreate);
				}}
				onClosed={() => {
					createApi.reset();
				}}
			>
				<ModalHeader>Lägg till användare</ModalHeader>
				{(managerListResult.status === 1 || sitesListResult.status === 1) && (
					<ModalBody>
						<LoadingSpinner />
					</ModalBody>
				)}
				{managerListResult.status === 2 && sitesListResult.status === 2 && (
					<ModalBody>
						<Row>
							<Col xs={12}>
								<Formik
									initialValues={{
										name: "",
										email: "",
										mobilePhone: "",
										personalIdentityNumber: "",
										tempPassword: randPassword(3, 2, 2, 1),
										managerId: null,
										siteId: null,
										roles: [],
										companyId: match.params.id,
									}}
									onSubmit={(values) => {
										// same shape as initial values

										createApi.post(apiBaseUrl, values);
									}}
									validationSchema={validationSchema}
								>
									{({ errors, values, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
										<Form onSubmit={handleSubmit} noValidate>
											<ItemForm
												values={values}
												errors={errors}
												touched={touched}
												handleBlur={handleBlur}
												handleChange={handleChange}
												setFieldValue={setFieldValue}												
												managerList={managerListResult.data}
												sitesList={sitesListResult.data}
											/>
											<FormGroup>
												<Row>
													<Col xs={6}>
														<Button
															block
															color="white"
															onClick={() => {
																setShowCreate(false);
															}}
														>
															Avbryt
														</Button>
													</Col>
													<Col xs={6}>
														<Button block disabled={createResult.status === 1} color="primary" type="submit">
															Lägg till
														</Button>
													</Col>
												</Row>
											</FormGroup>
										</Form>
									)}
								</Formik>
							</Col>
						</Row>

						<Row>
							<Col xs={12}>
								<AlertResultFeedback result={createResult} />
							</Col>
						</Row>
					</ModalBody>
				)}
			</Modal>
			<Modal
				isOpen={showEdit}
				toggle={() => {
					setShowEdit(!showEdit);
				}}
				onClosed={() => {
					editApi.reset();
				}}
			>
				<ModalHeader>Redigera användare</ModalHeader>
				{(managerListResult.status === 1 || editItemResult.status === 1 || sitesListResult.status === 1) && (
					<ModalBody>
						<LoadingSpinner />
					</ModalBody>
				)}
				{managerListResult.status === 2 && editItemResult.status === 2 && sitesListResult.status === 2 && (
					<ModalBody>
						<Row>
							<Col xs={12}>
								<Formik
									initialValues={{
										...editItemResult.data,
									}}
									onSubmit={(values) => {
										// same shape as initial values

										editApi.put(apiBaseUrl, values);
									}}
									validationSchema={validationSchema}
								>
									{({ errors, values, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
										<Form onSubmit={handleSubmit} noValidate>
											<FormGroup>
												<Row>
													<Col>
														<Button
															disabled={editResult.status === 1}
															onClick={() => {
																setDeleteId(values.id);
																setShowDelete(true);
															}}
															className="float-end mt-2"
															color="danger"
															type="button"
														>
															Radera
														</Button>
													</Col>
												</Row>
											</FormGroup>
											<ItemForm
												isEdit={true}
												handleSubmit={handleSubmit}
												handleBlur={handleBlur}
												values={values}
												errors={errors}
												touched={touched}
												handleChange={handleChange}
												setFieldValue={setFieldValue}
												managerList={managerListResult.data}
												sitesList={sitesListResult.data}
											/>
											<FormGroup>
												<Row>
													<Col xs={6}>
														<Button
															block
															color="white"
															onClick={() => {
																setShowEdit(false);
															}}
														>
															Avbryt
														</Button>
													</Col>
													<Col xs={6}>
														<Button block disabled={editResult.status === 1} color="primary" type="submit">
															Spara
														</Button>
													</Col>
												</Row>
											</FormGroup>
										</Form>
									)}
								</Formik>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<AlertResultFeedback result={editResult} />
							</Col>
						</Row>
					</ModalBody>
				)}
			</Modal>
			<Modal
				isOpen={showDelete}
				toggle={() => {
					setShowDelete(!showDelete);
				}}
				onClosed={() => {
					deleteApi.reset();
				}}
			>
				<ModalHeader>Radera användare</ModalHeader>
				{deleteId && (
					<ModalBody>
						<h4>Är du säker du vill radera användaren?</h4>
						<Row>
							<Col xs={6}>
								<Button
									block
									color="white"
									onClick={() => {
										setShowDelete(false);
										setDeleteId(null);
									}}
								>
									Avbryt
								</Button>
							</Col>
							<Col xs={6}>
								<Button
									disabled={deleteResult.status === 1}
									block
									color="danger"
									onClick={() => {
										deleteApi.del(apiBaseUrl.concat(deleteId));
									}}
								>
									Radera
								</Button>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<AlertResultFeedback result={deleteResult} />
							</Col>
						</Row>
					</ModalBody>
				)}
			</Modal>
		</>
	);
};
export default CrudPage;
