import React, { useMemo, useEffect, useState } from "react";
import PageHeader from "components/PageHeader";
import Panel from "components/Panel";
import { Button, ButtonGroup, Col, FormGroup, Row } from "reactstrap";
import DataTable from "components/DataTable";
import Datetime from "react-datetime";
import "chartjs-adapter-date-fns";
import moment from "moment";
import { useApi } from "components/useApi3";
import FormatCurrency from "components/FormatCurrency";
import IncidentStatus, { incidentStatusName } from "components/IncidentStatus";
import InfoCard from "./components/InfoCard";
import Chart from "./components/Chart";

const Incidents = () => {
	var firstDay = moment().startOf("month");
	var lastDay = moment().endOf("month");

	const [filters, setFilters] = useState({
		fromDate: firstDay,
		toDate: lastDay,
	});

	// din kostnad vs trafikområde you,site
	// switchar mellan att visa "siteData" och "userData" i chart och för "site" visas ingen table
	// även infocard rutan visar olika baseat på costMode
	const [costMode, setCostMode] = useState("you");

	const [dataResult, dataApi] = useApi();

	useEffect(() => {
		const url = "api/incident/reports/userincidentsummary"
			.concat("?fromDate=")
			.concat(filters.fromDate.toISOString())
			.concat("&toDate=")
			.concat(filters.toDate.toISOString());

		dataApi.get(url);
	}, [filters, dataApi]);

	const columns = useMemo(
		() => [
			{
				accessor: "incidentDate",
				Header: "Incidentdatum",
				Cell: (cell) => {
					return moment(cell.row.original.incidentDate).format("YYYY-MM-DD");
				},
			},
			{
				accessor: (row) => row.vehicleIdentifier.concat(" - ").concat(row.vehicleRegistrationNumber).concat(" - ").concat(row.vehicleModel),
				Header: "Fordon",
			},

			{
				accessor: "siteName",
				Header: "Trafikområde",
			},
			{
				accessor: "estimatedCostForInjury",
				Header: "Beräknad kostnad",
				Cell: (cell) => {
					return <FormatCurrency value={cell.row.original.estimatedCostForInjury} />;
				},
			},
			{
				accessor: "compensationAmount",
				Header: "Ersättningskostnad",
				Cell: (cell) => {
					return <FormatCurrency value={cell.row.original.compensationAmount} />;
				},
			},
			{
				accessor: "counterpartCost",
				Header: "Motpartskostnad",
				Cell: (cell) => {
					return <FormatCurrency value={cell.row.original.counterpartCost} />;
				},
			},
			{
				accessor: row => incidentStatusName(row.status),
				Header: "Status",
				Cell: (cell) => {
					return <IncidentStatus status={cell.row.original.status} />;
				},
			},
			{
				accessor: "createdDate",
				Header: "Skapad",
				Cell: (cell) => {
					return moment(cell.row.original.createdDate).format("YYYY-MM-DD");
				},
			},
			{
				accessor: "createdByName",
				Header: "Skapad av",
			},
		],
		[]
	);

	const tableRows = dataResult.data && dataResult.data.incidents ? dataResult.data.incidents : [];

	return (
		<>
			<PageHeader title="Uppföljning" subtitle="Här kan du jämföra dina kostnader och ditt trafikområde" />
			<Panel>
				<Row>
					<Col xs={12} sm={6}>
						<div className="d-flex justify-content-end">
							<Row>
								<Col sm={8}>
									<ButtonGroup className="me-sm-2">
										<Button
											onClick={() => {
												setCostMode("you");
											}}
											color="white"
											className="btn-form-input"
										>
											Din Kostnad
										</Button>
										<Button
											onClick={() => {
												setCostMode("site");
											}}
											color="white"
											className="btn-form-input"
										>
											Trafikområde
										</Button>
									</ButtonGroup>
								</Col>
								<Col sm={4} className="mt-2 mt-sm-0">
									<ButtonGroup>
										<Button
											onClick={() => {
												var firstDay = moment().subtract(1, "months");
												var lastDay = moment();

												setFilters((f) => {
													return {
														...f,
														fromDate: firstDay,
														toDate: lastDay,
													};
												});
											}}
											color="white"
											className="btn-form-input"
										>
											Månad
										</Button>
										<Button
											onClick={() => {
												var firstDay = moment().subtract(3, "months");
												var lastDay = moment();

												setFilters((f) => {
													return {
														...f,
														fromDate: firstDay,
														toDate: lastDay,
													};
												});
											}}
											color="white"
											className="btn-form-input"
										>
											Kvartal
										</Button>
										<Button
											onClick={() => {
												var firstDay = moment().subtract(1, "years");
												var lastDay = moment();

												setFilters((f) => {
													return {
														...f,
														fromDate: firstDay,
														toDate: lastDay,
													};
												});
											}}
											color="white"
											className="btn-form-input"
										>
											År
										</Button>
									</ButtonGroup>
								</Col>
							</Row>
						</div>
					</Col>
					<Col xs={12} sm={6} className="mt-2 mt-sm-0">
						<div className="d-flex justify-content-end">
							<FormGroup className="me-2">
								<Datetime
									onChange={(e) => {
										const value = e;
										setFilters((f) => {
											return {
												...f,
												fromDate: value,
											};
										});
									}}
									value={filters.fromDate}
									timeFormat={false}
									inputProps={{ placeholder: "Från datum", className: "form-control" }}
								/>
							</FormGroup>
							<FormGroup>
								<Datetime
									onChange={(e) => {
										const value = e;
										setFilters((f) => {
											return {
												...f,
												toDate: value,
											};
										});
									}}
									value={filters.toDate}
									timeFormat={false}
									inputProps={{ placeholder: "Till datum", className: "form-control" }}
								/>
							</FormGroup>
						</div>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col sm={9}>
						<Chart result={dataResult} costMode={costMode} />
					</Col>
					<Col sm={3}>
						<InfoCard result={dataResult} costMode={costMode} />
					</Col>
				</Row>
				{costMode === "you" && (
					<Row>
						<Col xs={12} className="mt-4">
							<hr />
							<DataTable columns={columns} result={dataResult} rowData={tableRows} />
						</Col>
					</Row>
				)}
			</Panel>
		</>
	);
};

export default Incidents;
