import React, { useState } from "react";
import axios from "axios";
import authService from './api-authorization/AuthorizeService';

function getMessages(error) {
    const { response } = error;
    const { request, ...errorObject } = response; // take everything but 'request'

    if (errorObject) {
        if (errorObject.status === 400) {
            if (Array.isArray(errorObject.data)) {
                return errorObject.data;
            }
        } else if (errorObject.status === 401) {
            return ["Behörighet saknas för den valda operationen"];
        } else if (errorObject.status === 500) {
            if (Array.isArray(errorObject.data)) {
                return errorObject.data;
            }
        }
    }

    return ["Ett okänt fel inträffade"];
}


function redirectToLoginOn401(error) {
    const { response } = error;
    const { request, ...errorObject } = response; // take everything but 'request'

    if (errorObject) {
         if (errorObject.status === 401) {
            window.location.href = "/authentication/login";
         }
    }

}

export const useApi = () => {
    const ignoreResult = React.useRef(false);

    const [result, setResult] = useState({
        status: 0, // 0 idle, 1 loading, 2 success, 3 error
        messages: [], // onlyif 3
        data: null // only valid if 2
    });

    const get = React.useCallback(async (url) => {
        setResult({
            status: 1,
            messages: [],
            data: null
        });

        const accessToken = await authService.getAccessToken();

        try {
            var result = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            if (!ignoreResult.current) {
                // hit kommer vi på "ok"
                setResult({
                    status: 2,
                    messages: [],
                    data: result.data
                });
            } else {
                //console.log("ignoring result");
            }
        } catch (e) {

            redirectToLoginOn401(e);

            try {
                //console.log(e);
                if (!ignoreResult.current) {
                    setResult({
                        status: 3,
                        messages: getMessages(e),
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            } catch {
                if (!ignoreResult.current) {
                    setResult({
                        status: 3,
                        messages: [],
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            }
        }
    }, []);
    const getBlob = React.useCallback(async (url, fileName) => {
        setResult({
            status: 1,
            messages: [],
            data: null
        });
        const accessToken = await authService.getAccessToken();

        try {
            axios({
                url: url, //your url
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                method: "GET",
                responseType: "blob" // important
            }).then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName); //or any other extension
                document.body.appendChild(link);
                link.click();

                if (!ignoreResult.current) {
                    // hit kommer vi på "ok"
                    setResult({
                        status: 2,
                        messages: [],
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            });
        } catch (e) {

            redirectToLoginOn401(e);

            try {
                //console.log(e);
                if (!ignoreResult.current) {
                    setResult({
                        status: 3,
                        messages: getMessages(e),
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            } catch {
                if (!ignoreResult.current) {
                    setResult({
                        status: 3,
                        messages: [],
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            }
        }
    }, []);

    const post = React.useCallback(async (url, data) => {
        setResult({
            status: 1,
            messages: [],
            data: null
        });

        const accessToken = await authService.getAccessToken();

        try {
            var result = await axios.post(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            // hit kommer vi på "ok"
            if (!ignoreResult.current) {
                setResult({
                    status: 2,
                    messages: [],
                    data: result.data
                });
            } else {
                //console.log("ignoring result");
            }
        } catch (e) {
            
            redirectToLoginOn401(e);

            try {
                //console.log(e);
                if (!ignoreResult.current) {
                    setResult({
                        status: 3,
                        messages: getMessages(e),
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            } catch {
                if (!ignoreResult.current) {
                    setResult({
                        status: 3,
                        messages: [],
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            }
        }
    }, []);

    const put = React.useCallback(async (url, data) => {
        setResult({
            status: 1,
            messages: [],
            data: null
        });

        const accessToken = await authService.getAccessToken();

        try {
            var result = await axios.put(url, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (!ignoreResult.current) {
                // hit kommer vi på "ok"
                setResult({
                    status: 2,
                    messages: [],
                    data: result.data
                });
            } else {
                //console.log("ignoring result");
            }
        } catch (e) {
            
            redirectToLoginOn401(e);

            try {
                //console.log(e);
                if (!ignoreResult.current) {
                    setResult({
                        status: 3,
                        messages: getMessages(e),
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            } catch {
                if (!ignoreResult.current) {
                    setResult({
                        status: 3,
                        messages: [],
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            }
        }
    }, []);

    const del = React.useCallback(async (url, data) => {
        setResult({
            status: 1,
            messages: [],
            data: null
        });

        const accessToken = await authService.getAccessToken();

        try {
            var result = await axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            if (!ignoreResult.current) {
                // hit kommer vi på "ok"
                setResult({
                    status: 2,
                    messages: [],
                    data: result.data
                });
            } else {
                //console.log("ignoring result");
            }
        } catch (e) {

            redirectToLoginOn401(e);
            
            try {
                //console.log(e);
                if (!ignoreResult.current) {
                    setResult({
                        status: 3,
                        messages: getMessages(e),
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            } catch {
                if (!ignoreResult.current) {
                    setResult({
                        status: 3,
                        messages: [],
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            }
        }
    }, []);

    const file = React.useCallback(async (url, data) => {
        setResult({
            status: 1,
            messages: [],
            data: null
        });
        
        const accessToken = await authService.getAccessToken();

        try {
            const formData = new FormData();
            formData.append("file", data);

            var result = await axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "multipart/form-data"
                }
            });
            if (!ignoreResult.current) {
                // hit kommer vi på "ok"
                setResult({
                    status: 2,
                    messages: [],
                    data: result.data
                });
            } else {
                //console.log("ignoring result");
            }
        } catch (e) {
            
            redirectToLoginOn401(e);

            try {
                //console.log(e);
                if (!ignoreResult.current) {
                    setResult({
                        status: 3,
                        messages: getMessages(e),
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            } catch {
                if (!ignoreResult.current) {
                    setResult({
                        status: 3,
                        messages: [],
                        data: null
                    });
                } else {
                    //console.log("ignoring result");
                }
            }
        }
    }, []);

    const leave = React.useCallback(() => {
        //console.log("leave");
        ignoreResult.current = true;
    }, []);

    const reset = React.useCallback(() => {
        //console.log("resetting request");
        ignoreResult.current = false;
        setResult({
            status: 0,
            messages: [],
            data: null
        });
    }, [setResult]);

    const apiFuncs = React.useMemo(() => {
        return {
            get,
            getBlob,
            post,
            put,
            del,
            file,
            leave,
            reset
        };
    }, [get, post, put, del, file, leave, reset, getBlob]);

    return [result, apiFuncs];
};
