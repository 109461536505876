import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, FormGroup, Row } from "reactstrap";
import Datetime from "react-datetime";
import "chartjs-adapter-date-fns";
import moment from "moment";
import { useApi } from "components/useApi3";
import Chart from "./Chart";
import InfoCardSite from "./InfoCardSite";
import InfoCardUser from "./InfoCardUser";

const IncidentsSummary = ({ isHandler, isReporter }) => {
	var firstDay = moment().startOf("month");
	var lastDay = moment().endOf("month");

	const [filters, setFilters] = useState({
		fromDate: firstDay,
		toDate: lastDay,
	});

	const [dataResult, dataApi] = useApi();

	useEffect(() => {
		const baseUrl = isReporter ? "api/incident/reports/userincidentsummary" : isHandler ? "api/incident/reports/companyincidentsummary" : "";

		const url = baseUrl.concat("?fromDate=").concat(filters.fromDate.toISOString()).concat("&toDate=").concat(filters.toDate.toISOString());

		dataApi.get(url);
	}, [filters, dataApi, isReporter, isHandler]);

	// visar Trafikområden om du endast är hanterare
	// visar Ditt Trafikområde om du är anmälare
	// verkar visa chart om man klickar på saker i orginalet
	// men allt data är baserat på en "incidentSummary" precis som InfoCard i reports och uppföljning
	return (
		<>
			<Row>
				<Col xs={12} sm={6}>
					<div className="d-flex justify-content-end">
						<ButtonGroup>
							<Button
								onClick={() => {
									var firstDay = moment().subtract(1, "months");
									var lastDay = moment();

									setFilters((f) => {
										return {
											...f,
											fromDate: firstDay,
											toDate: lastDay,
										};
									});
								}}
								color="white"
								className="btn-form-input"
							>
								Månad
							</Button>
							<Button
								onClick={() => {
									var firstDay = moment().subtract(3, "months");
									var lastDay = moment();

									setFilters((f) => {
										return {
											...f,
											fromDate: firstDay,
											toDate: lastDay,
										};
									});
								}}
								color="white"
								className="btn-form-input"
							>
								Kvartal
							</Button>
							<Button
								onClick={() => {
									var firstDay = moment().subtract(1, "years");
									var lastDay = moment();

									setFilters((f) => {
										return {
											...f,
											fromDate: firstDay,
											toDate: lastDay,
										};
									});
								}}
								color="white"
								className="btn-form-input"
							>
								År
							</Button>{" "}
						</ButtonGroup>
					</div>
				</Col>
				<Col xs={12} sm={6} className="mt-2 mt-sm-0">
					<div className="d-flex justify-content-end">
						<FormGroup className="me-2">
							<Datetime
								onChange={(e) => {
									const value = e;
									setFilters((f) => {
										return {
											...f,
											fromDate: value,
										};
									});
								}}
								value={filters.fromDate}
								timeFormat={false}
								inputProps={{ placeholder: "Från datum", className: "form-control" }}
							/>
						</FormGroup>
						<FormGroup>
							<Datetime
								onChange={(e) => {
									const value = e;
									setFilters((f) => {
										return {
											...f,
											toDate: value,
										};
									});
								}}
								value={filters.toDate}
								timeFormat={false}
								inputProps={{ placeholder: "Till datum", className: "form-control" }}
							/>
						</FormGroup>
					</div>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Chart result={dataResult} />
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<InfoCardSite result={dataResult} />
					{isReporter && <InfoCardUser result={dataResult} />}
				</Col>
			</Row>
		</>
	);
};

export default IncidentsSummary;
