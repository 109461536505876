import { Alert } from "reactstrap";

const AlertResultFeedback = ({ result, loadingMesage, doneMessage }) => {
	return result.status === 1 ? (
		<Alert className="my-2" color="info">
			{loadingMesage ? loadingMesage : "Vänta.."}
		</Alert>
	) : result.status === 2 ? (
		<Alert className="my-2" color="info">
			{doneMessage ? doneMessage : "Vänta.. Klar!"}			
		</Alert>
	) : result.status > 2 ? (
		<Alert className="my-2" color="danger">
			Ett fel inträffade.
            <ul>
                {result.messages.map((item,i) => {
                    return <li key={i}>{item}</li>
                })}
            </ul>
		</Alert>
	) : null;
};

export default AlertResultFeedback;
