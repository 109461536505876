import React from 'react';
import { Badge } from 'reactstrap';

export const incidentStatusName = (status) => {
    const statusLowered = status ? status.toLowerCase() : '';

    return  statusLowered === "open" ? "Öppen"
    : statusLowered === "sent" ? "Skickad"
    : statusLowered === "completed" ? "Avslutad"
    : ""
}

const IncidentStatus = ({status}) => {
    const statusLowered = status ? status.toLowerCase() : '';

    return statusLowered === "open" ? <Badge color="primary">Öppen</Badge>
        : statusLowered === "sent" ? <Badge color="warning">Skickad</Badge>
        : statusLowered === "completed" ? <Badge color="success">Avslutad</Badge>
        : null
    
}

export default IncidentStatus;
