import React, { useContext } from "react";
import { AppContext } from "AppContext";
import { Col, Row } from "reactstrap";
import Panel from "components/Panel";
import Hero from "components/Hero";
import IncidentsSummary from "./components/IncidentsSummary";
import HandlerIncidentList from "./components/HandlerIncidentList";

const Dashboard = () => {
	const authContext = useContext(AppContext);

	const isReporter = authContext.userRoles && authContext.userRoles.indexOf("IncidentReporter") !== -1;
	const isHandler = authContext.userRoles && authContext.userRoles.indexOf("IncidentHandler") !== -1;

	return (
		<>
			<Row>
				<Col sm={4}>
					<Panel>
						<Hero isIncidentReporter={isReporter} />
					</Panel>
				</Col>
				<Col sm={8}>
					{isHandler && (
						<Panel>
							<h1>Ärenden</h1>
							<HandlerIncidentList />
						</Panel>
					)}
					{(isHandler || isReporter) && (
						<Panel>
							{isReporter ? <h1>Ditt trafikområde</h1> : <h1>Trafikområden</h1>}
							<IncidentsSummary isHandler={isHandler} isReporter={isReporter} />
						</Panel>
					)}
				</Col>
			</Row>
		</>
	);
};

export default Dashboard;
