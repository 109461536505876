import React, { Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import { NavItem } from "reactstrap";
import { AppContext } from "../../AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnalytics, faCogs, faFileSignature, faHomeAlt, faListAlt, faTasks } from "@fortawesome/pro-light-svg-icons";

const TopCommonMenu = ({ onItemClick }) => {
	const authContext = useContext(AppContext);

	return (
		<>
			{authContext.userRoles &&
			(authContext.userRoles.indexOf("IncidentHandler") !== -1 ||
				authContext.userRoles.indexOf("IncidentReporter") !== -1 ||
				authContext.userRoles.indexOf("SuperAdmin") !== -1 ||
				authContext.userRoles.indexOf("CompanyAdmin") !== -1) ? (
				<NavItem>
					<NavLink exact to="/" activeClassName="active" className="nav-link" onClick={onItemClick}>
						<FontAwesomeIcon icon={faHomeAlt} className="me-2" />
						Översikt
					</NavLink>
				</NavItem>
			) : null}
			{authContext.userRoles && (authContext.userRoles.indexOf("IncidentHandler") !== -1 || authContext.userRoles.indexOf("IncidentReporter") !== -1) ? (
				<NavItem>
					<NavLink to="/incidents/create" activeClassName="active" className="nav-link" onClick={onItemClick}>
						<FontAwesomeIcon icon={faFileSignature} className="me-2" />
						Anmäl skada
					</NavLink>
				</NavItem>
			) : null}
		</>
	);
};

const BottomCommonMenu = ({ onItemClick }) => {
	const authContext = useContext(AppContext);

	return (
		<>
			{authContext.userRoles && (authContext.userRoles.indexOf("IncidentHandler") !== -1 || authContext.userRoles.indexOf("CompanyAdmin") !== -1) ? (
				<NavItem>
					<NavLink to="/reports/incidents" activeClassName="active" className="nav-link" onClick={onItemClick}>
						<FontAwesomeIcon icon={faAnalytics} className="me-2" />
						Rapporter
					</NavLink>
				</NavItem>
			) : null}
		</>
	);
};

const HandlerMenu = ({ onItemClick }) => {
	const authContext = useContext(AppContext);

	return authContext.userRoles && authContext.userRoles.indexOf("IncidentHandler") !== -1 ? (
		<>
			<NavItem>
				<NavLink to="/incidents/list" activeClassName="active" className="nav-link" onClick={onItemClick}>
					<FontAwesomeIcon icon={faFileSignature} className="me-2" />
					Hantera ärenden
				</NavLink>
			</NavItem>
		</>
	) : null;
};
const ReporterMenu = ({ onItemClick }) => {
	const authContext = useContext(AppContext);

	return authContext.userRoles && (authContext.userRoles.indexOf("IncidentReporter") !== -1 || authContext.userRoles.indexOf("IncidentHandler") !== -1) ? (
		<>
			<NavItem>
				<NavLink to="/incidents/mine" activeClassName="active" className="nav-link" onClick={onItemClick}>
					<FontAwesomeIcon icon={faListAlt} className="me-2" />
					Mina ärenden
				</NavLink>
			</NavItem>
			<NavItem>
				<NavLink to="/incidents/followup" activeClassName="active" className="nav-link" onClick={onItemClick}>
					<FontAwesomeIcon icon={faTasks} className="me-2" />
					Uppföljning
				</NavLink>
			</NavItem>
		</>
	) : null;
};
const AdminMenu = ({ onItemClick }) => {
	const authContext = useContext(AppContext);

	return authContext.userRoles && authContext.userRoles.indexOf("CompanyAdmin") !== -1 ? (
		<NavItem>
			<NavLink to="/admin/company" activeClassName="active" className="nav-link" onClick={onItemClick}>
				<FontAwesomeIcon icon={faCogs} className="me-2" />
				Bolagsinställningar
			</NavLink>
		</NavItem>
	) : null;
};

const SuperAdminMenu = ({ onItemClick }) => {
	const authContext = useContext(AppContext);

	return authContext.userRoles && authContext.userRoles.indexOf("SuperAdmin") !== -1 ? (
		<>
			<NavItem>
				<NavLink to="/superadmin/companies" activeClassName="active" className="nav-link" onClick={onItemClick}>
					<FontAwesomeIcon icon={faCogs} className="me-2" />
					Bolag
				</NavLink>
			</NavItem>
			<NavItem>
				<NavLink to="/superadmin/import" activeClassName="active" className="nav-link" onClick={onItemClick}>
					<FontAwesomeIcon icon={faFileSignature} className="me-2" />
					Import
				</NavLink>
			</NavItem>
		</>
	) : null;
};

export const AuthMenuItems = (props) => {
	const authContext = useContext(AppContext);
	const loginPath = `${ApplicationPaths.Login}`;

	return authContext.isAuth ? (
		<Fragment>
			<TopCommonMenu onItemClick={props.onItemClick}></TopCommonMenu>
			<ReporterMenu onItemClick={props.onItemClick}></ReporterMenu>
			<HandlerMenu onItemClick={props.onItemClick}></HandlerMenu>
			<BottomCommonMenu onItemClick={props.onItemClick}></BottomCommonMenu>
			<AdminMenu onItemClick={props.onItemClick}></AdminMenu>
			<SuperAdminMenu onItemClick={props.onItemClick}></SuperAdminMenu>
		</Fragment>
	) : (
		<Fragment>
			<NavItem>
				<NavLink className="text-dark" to={loginPath}>
					Logga in
				</NavLink>
			</NavItem>
		</Fragment>
	);
};
