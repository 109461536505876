import moment from "moment";

const DateFormat = ({children, withTime}) => {

    const format = withTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";

    return children ? moment(children).format(format) : "-";
}

export default DateFormat;
