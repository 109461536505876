// hantera ärende formulär-inputs
import Switch from "components/InputSwitch";
import NumberField from "components/NumberField";
import { FormGroup, Label, Input, Col, Row } from "reactstrap";
import Datetime from "react-datetime";

export const HandleIncidentFormInputs = ({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => {
	return (
		<>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Switch
							onChange={() => setFieldValue("isInsuranceMatter", !values.isInsuranceMatter)}
							checked={values.isInsuranceMatter}
							label="Försäkringsärende"
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Bärgningsföretag</Label>
						<Input
							className={errors.towingCompany ? "is-invalid" : ""}
							autoComplete="off"
							name="towingCompany"
							value={values.towingCompany}
							type="text"
							onChange={handleChange}
							onBlur={handleBlur}
						></Input>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Beräknad kostnad för vållad skada</Label>
						<NumberField
							value={values.estimatedCostForInjury}
							onChange={handleChange}
							name="estimatedCostForInjury"
							onBlur={handleBlur}
							className={errors.estimatedCostForInjury ? "is-invalid" : ""}
						/>
						{errors.estimatedCostForInjury ? <div className="invalid-feedback d-block">{errors.estimatedCostForInjury}</div> : null}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Motpartskostnad</Label>
						<NumberField
							value={values.counterpartCost}
							onChange={handleChange}
							name="counterpartCost"
							onBlur={handleBlur}
							className={errors.counterpartCost ? "is-invalid" : ""}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Materiella skador (på annat än fordon)</Label>
						<Input
							className={errors.materialDamages ? "is-invalid" : ""}
							autoComplete="off"
							name="materialDamages"
							value={values.materialDamages}
							type="textarea"
							onChange={handleChange}
							onBlur={handleBlur}
						></Input>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Datum för uppföljningssamtal</Label>
						<Datetime
							onChange={(e) => {
								setFieldValue("followUpTalkDate",e);
							}}
							timeFormat={false}
							inputProps={{ placeholder: "Datum för uppföljningssamtal", className: "form-control" }}
							value={values.followUpTalkDate}
						/>
						{errors.followUpTalkDate ? <div className="invalid-feedback d-block">{errors.followUpTalkDate}</div> : null}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Åtgärd</Label>
						<Input
							className={errors.action ? "is-invalid" : ""}
							autoComplete="off"
							name="action"
							value={values.action}
							type="textarea"
							onChange={handleChange}
							onBlur={handleBlur}
						></Input>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Övriga noteringar</Label>
						<Input
							className={errors.additionalNotes ? "is-invalid" : ""}
							autoComplete="off"
							name="additionalNotes"
							value={values.additionalNotes}
							type="textarea"
							onChange={handleChange}
							onBlur={handleBlur}
						></Input>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Status</Label>
						<Input
							type="select"
							name="status"
							id="status"
							value={values.status}
							className={errors.status ? "form-control is-invalid" : "form-control"}
							onChange={handleChange}
							onBlur={handleBlur}
						>
							<option value="Open">Öppen</option>
							<option value="Sent">Skickad</option>
							<option value="Completed">Avslutad</option>
						</Input>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Skadenummer</Label>
						<Input
							className={errors.damageNumber ? "is-invalid" : ""}
							autoComplete="off"
							name="damageNumber"
							value={values.damageNumber}
							type="text"
							onChange={handleChange}
							onBlur={handleBlur}
						></Input>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Switch
							onChange={() => setFieldValue("supplementedForInsuranceCompany", !values.supplementedForInsuranceCompany)}
							checked={values.supplementedForInsuranceCompany}
							label="Kompletterad till försäkringsbolag"
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Utfall</Label>
						<Input
							className={errors.result ? "is-invalid" : ""}
							autoComplete="off"
							name="result"
							value={values.result}
							type="textarea"
							onChange={handleChange}
							onBlur={handleBlur}
						></Input>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Reglering klar, datum</Label>
						<Datetime
							onChange={(e) => {
								setFieldValue("handlingDoneDate", e);
							}}
							timeFormat={false}
							inputProps={{ placeholder: "Reglering klar, datum", className: "form-control" }}
							value={values.handlingDoneDate}
						/>
						{errors.handlingDoneDate ? <div className="invalid-feedback d-block">{errors.handlingDoneDate}</div> : null}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<Label>Ersättningsbelopp</Label>
						<NumberField
							value={values.compensationAmount}
							onChange={handleChange}
							name="compensationAmount"
							onBlur={handleBlur}
							className={errors.compensationAmount ? "is-invalid" : ""}
						/>
					</FormGroup>
				</Col>
			</Row>
		</>
	);
};
