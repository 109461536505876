import React, { useMemo, useEffect, useState } from "react";
import PageHeader from "components/PageHeader";
import Panel from "components/Panel";
import { Alert, Button, ButtonGroup, Col, FormGroup, List, Row } from "reactstrap";
import DataTable from "components/DataTable";
import { useApi } from "components/useApi3";
import FileUpload from "./components/FileUpload";

const Import = () => {
	const [importItemsResult, importItemsApi] = useApi();
	const [processResult, processApi] = useApi();
	const [triggerRefresh, setTriggerRefresh] = useState();
    const [deleteProcessedResult, deleteProcessedApi] = useApi();
    const [deleteUnprocessedResult, deleteUnprocessedApi] = useApi();
	
	useEffect(() => {
		importItemsApi.get("api/superadmin/import/getall");
	}, [importItemsApi, triggerRefresh]);

	useEffect(() => {
		if (processResult.status === 2) importItemsApi.get("api/superadmin/import/getall");
	}, [processResult, importItemsApi]);

    useEffect(() => {
		if (deleteProcessedResult.status === 2) importItemsApi.get("api/superadmin/import/getall");
    },[deleteProcessedResult,importItemsApi]);

	useEffect(() => {
		if (deleteUnprocessedResult.status === 2) importItemsApi.get("api/superadmin/import/getall");
    },[deleteUnprocessedResult,importItemsApi]);

	const columns = useMemo(
		() => [
			{
				accessor: "id",
				Header: "ID",
			},
			{
				accessor: "type",
				Header: "Typ",
			},
			{
				accessor: "info",
				Header: "Info",
			},
			{
				accessor: "isProcessed",
				Header: "Behandlad",
				Cell: (cell) => {
					return cell.row.original.isProcessed ? "Ja" : "Nej";
				},
			},
			{
				accessor: "processResult",
				Header: "Resultat",
				Cell: (cell) => {
					return cell.row.original.processResult && cell.row.original.isProcessed ? "OK" : "";
				},
			},
			{
				accessor: "resultMessage",
				Header: "Meddelande",
			},
		],
		[]
	);

	const tableRows = importItemsResult.data ? importItemsResult.data : [];

	return (
		<>
			<PageHeader title="Import" subtitle="Här kan du som superadmin importera användare, orter och fordon för ett bolag" />
			<Panel>
				<Row>
					<Col>
						Import görs genom att man laddar upp en semikolon-separerad (';') CSV-fil. På varje rad finns angivet ett bolagsnamn, det måste man skapa innan man börjar importera.
					</Col>
				</Row>
				<Row>
					<Col>
						Formatet på filen ska vara följande:
						<ul>
							<li><strong>Första raden ska endast vara Vehicle, Site eller User beroende på vilken typ av import man kan göra</strong></li>
                            <li>För Site (Ort) ska raderna innehålla dessa kolumner
                                <ul>
                                    <li>Bolagsnamn</li>
                                    <li>Namn</li>
                                </ul>
                            </li>
                            <li>För Vehicle (Fordon) ska raderna innehålla dessa kolumner
                                <ul>
                                    <li>Bolagsnamn</li>
                                    <li>Regnummer</li>
                                    <li>Id-nummer</li>
                                    <li>Modell</li>
                                </ul>
                            </li>
                            <li>För User (Användare) ska raderna innehålla dessa kolumner
                                <ul>
                                    <li>Bolagsnamn</li>
                                    <li>Namn</li>
                                    <li>Epost</li>
                                    <li>Personnummer</li>
                                    <li>Mobilnummer</li>
                                    <li>Ort-namn</li>
                                    <li>Ärendehanterare Namn</li>
                                    <li>IsCompanyAdmin (ska innehålla minst ett tecken för Sant, annars tomt för Falskt)</li>
                                    <li>IsIncidentHandler (ska innehålla minst ett tecken för Sant, annars tomt för Falskt)</li>
                                    <li>IsIncidentReporter (ska innehålla minst ett tecken för Sant, annars tomt för Falskt)</li>
                                </ul>
                            </li>
						</ul>
					</Col>
				</Row>
				<Row>
					<Col>
						Ladda upp fil: <br />
						<FileUpload
							onSuccessUpload={(uploadedImage) => {
								processApi.reset();

								setTriggerRefresh((prev) => prev + 1);
							}}
						/>
						När filen är uppladdad skapas import-rader utifrån datat i filen. De visas i tabellen nedan, och sedan kan man välja att behandla dessa importrader,
						då läggs data in i databasen.
						<br />
                        Obs, av någon anledning kan man inte ladda upp en fil med samma namn två ggr i rad (t.ex. om det blivit fel) så då får man ladda om sidan innan man laddar upp filen på nytt<br />
						<Button
							onClick={() => {
								processApi.post("api/superadmin/import/importunprocessed");
							}}
							color="primary"
							type="button"
						>
							Importera alla oimporterade (Behandlad = Nej)
						</Button>
						{processResult.status === 1 ? (
							<Alert color="info">Behandlar alla obehandlade importrader</Alert>
						) : processResult.status === 2 ? (
							<Alert color="success">Import klar</Alert>
						) : processResult.status > 2 ? (
							<Alert color="danger">Någonting gick snett</Alert>
						) : null}
					</Col>
				</Row>
			</Panel>
			<Panel>
				<Row>
					<Col xs={12}>
						<DataTable columns={columns} result={importItemsResult} rowData={tableRows} />
					</Col>
				</Row>
                <Row className="mt-4">
                    <Col>
                    <Button
                            disabled={deleteProcessedResult.status === 1}
							onClick={() => {
								deleteProcessedApi.post("api/superadmin/import/deleteprocessed");
							}}
							color="warning"
							type="button"
						>
							Radera alla behandlade (Behandlad = Ja)
						</Button>
                        
                        </Col>
                        <Col>
                        <Button
                            disabled={deleteUnprocessedResult.status === 1}
							onClick={() => {
								deleteUnprocessedApi.post("api/superadmin/import/deleteunprocessed");
							}}
							color="danger"
							type="button"
						>
							Radera alla obehandlade (Behandlad = Nej)
						</Button>
                        </Col>
                </Row>
			</Panel>
		</>
	);
};
export default Import;
