import React, { useState } from "react";
import { Row, Col, FormGroup, Label, Input, Button, ListGroup, ListGroupItem } from "reactstrap";

const CounterPartFormList = ({ items, onAdd, onRemove }) => {
	const [state, setState] = useState({
		name: "",
		registrationNumber: "",
		personalOrOrganisationNumber: "",
		telephoneNumber: "",
	});

	const updateField = (e) => {
		const type = e.target.type;
		const name = e.target.name;
		const value = type === "checkbox" ? e.target.checked : e.target.value;

		setState({
			...state,
			[name]: value,
		});
	};

	return (
		<FormGroup>
			<Row>
				<Col xs={12}>
					<Label>Kontaktuppgifter motpart</Label>
					<table className="table table-discreet d-none d-sm-table">
						<thead>
							<tr>
								<th>Namn</th>
								<th>Registreringsnummer</th>
								<th>Person- eller organisationsnummer</th>
								<th>Telefonnummer</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{items.length === 0 && (
								<tr>
									<td colSpan={5}>Fyll i namn, registreringsnummer, person- eller organisationsnummer och telefonnummer och klicka på "Lägg till"</td>
								</tr>
							)}
							{items.map((item, key) => {
								return (
									<tr>
										<td>{item.name}</td>
										<td>{item.registrationNumber}</td>
										<td>{item.personalOrOrganisationNumber}</td>
										<td>{item.telephoneNumber}</td>
										<td>
											<Button
												color="white"
												onClick={() => {
													onRemove(item.name);
												}}
											>
												Ta bort
											</Button>
										</td>
									</tr>
								);
							})}
							<tr className="table-active">
								<td>
									<Input placeholder="Namn" autoComplete="off" name="name" value={state.name} onChange={updateField}></Input>
								</td>
								<td>
									<Input
										placeholder="Registreringsnummer"
										autoComplete="off"
										name="registrationNumber"
										value={state.registrationNumber}
										onChange={updateField}
									></Input>
								</td>
								<td>
									<Input
										autoComplete="off"
										placeholder="Person- eller organisationsnummer"
										name="personalOrOrganisationNumber"
										value={state.personalOrOrganisationNumber}
										onChange={updateField}
									></Input>
								</td>
								<td>
									<Input placeholder="Telefonnummer" autoComplete="off" name="telephoneNumber" value={state.telephoneNumber} onChange={updateField}></Input>
								</td>
								<td>
									<Button
										color="primary"
										onClick={() => {
											onAdd({
												name: state.name,
												personalOrOrganisationNumber: state.personalOrOrganisationNumber,
												registrationNumber: state.registrationNumber,
												telephoneNumber: state.telephoneNumber,
											});
											setState({ name: "", registrationNumber: "", telephoneNumber: "", personalOrOrganisationNumber: "" });
										}}
										type="button"
									>
										Lägg till
									</Button>
								</td>
							</tr>
						</tbody>
					</table>
					<div className="d-block d-sm-none">
						<ListGroup>
							{items.length === 0 && (
								<ListGroupItem>Fyll i namn, registreringsnummer, person- eller organisationsnummer och telefonnummer och klicka på "Lägg till"</ListGroupItem>
							)}
							{items.map((item, key) => {
								return (
									<ListGroupItem>
										<div className="small-label">Namn</div>
										<h1>{item.name}</h1>
										<div className="small-label">Regnummer</div>
										<div>{item.registrationNumber}</div>
										<div className="small-label">Person- eller organisationsnummer</div>
										<div>{item.personalOrOrganisationNumber}</div>
										<div className="small-label">Telefonnummer</div>
										<div>{item.telephoneNumber}</div>
										<div>
											<Button
												block
												color="white"
												onClick={() => {
													onRemove(item.name);
												}}
											>
												Ta bort
											</Button>
										</div>
									</ListGroupItem>
								);
							})}
							<ListGroupItem>
								{items.length > 0 ? <Label>Lägg till ytterligare en kontakt</Label> : <Label>Lägg till kontakt</Label>}
								<FormGroup>
									<Input placeholder="Namn" autoComplete="off" name="name" value={state.name} onChange={updateField}></Input>
								</FormGroup>
								<FormGroup>
									<Input
										placeholder="Registreringsnummer"
										autoComplete="off"
										name="registrationNumber"
										value={state.registrationNumber}
										onChange={updateField}
									></Input>
								</FormGroup>
								<FormGroup>
									<Input
										autoComplete="off"
										placeholder="Person- eller organisationsnummer"
										name="personalOrOrganisationNumber"
										value={state.personalOrOrganisationNumber}
										onChange={updateField}
									></Input>
								</FormGroup>
								<FormGroup>
									<Input placeholder="Telefonnummer" autoComplete="off" name="telephoneNumber" value={state.telephoneNumber} onChange={updateField}></Input>
								</FormGroup>
								<FormGroup>
									<Button
										color="primary"
										block
										onClick={() => {
											onAdd({
												name: state.name,
												personalOrOrganisationNumber: state.personalOrOrganisationNumber,
												registrationNumber: state.registrationNumber,
												telephoneNumber: state.telephoneNumber,
											});
											setState({ name: "", registrationNumber: "", telephoneNumber: "", personalOrOrganisationNumber: "" });
										}}
										type="button"
									>
										Lägg till
									</Button>
								</FormGroup>
							</ListGroupItem>
						</ListGroup>
					</div>
				</Col>
			</Row>
		</FormGroup>
	);
};

export default CounterPartFormList;
