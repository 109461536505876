import React, { useEffect, useMemo } from "react";
import { useApi } from "components/useApi3";
import DataTable from "components/DataTable";

import { Button, ButtonGroup } from "reactstrap";
import incidentCategory from "components/IncidentCatogory";
import IncidentStatus from "components/IncidentStatus";
import IncidentProgress from "components/IncidentProgress";
import { ArrowRightIcon } from "components/Icons";
import { Link, useHistory } from "react-router-dom";

const filters = {
	statusOpen: true,
	statusSent: true,
	statusCompleted: true,
};

const HandlerIncidentList = () => {
	const history = useHistory();

	const [listResult, listApi] = useApi();

	useEffect(() => {
		listApi.get(
			"api/incident/handle?open=".concat(filters.statusOpen).concat("&sent=").concat(filters.statusSent).concat("&completed=").concat(filters.statusCompleted)
		);
	}, [listApi]);

	const columns = useMemo(
		() => [
			{
				accessor: row => incidentCategory(row.incidentVehicleDamage.insuranceCategory),
				Header: "Ärende",
			},
			{
				accessor: "status",
				Header: "Status",
				Cell: (cell) => {
					return <IncidentStatus status={cell.row.original.status} />;
				},
			},
			{
				accessor: "vehicle",
				Header: "Fordon",
				Cell: (cell) => {
					const item = cell.row.original;
					return <span>{item.vehicleIdentifier.concat(" - ").concat(item.vehicleRegistrationNumber).concat(" - ").concat(item.vehicleModel)}</span>;
				},
			},
			{
				accessor: "statusProgress",
				Header: "",
				Cell: (cell) => {
					return <IncidentProgress status={cell.row.original.status} />;
				},
			},
			{
				accessor: "commands",
				Cell: (cell) => {
					return (
						<ButtonGroup className="float-end">
							<Button
								color="white"
								onClick={() => {
									history.push("/incidents/edit/".concat(cell.row.original.id));
								}}
							>
								Hantera
							</Button>
						</ButtonGroup>
					);
				},
			},
		],
		[history]
	);

	return (
		<>
			<div className="d-flex justify-content-end">
				<Link className="large-link" to="/incidents/list">
					Visa alla ärenden <ArrowRightIcon />
				</Link>
			</div>
			<DataTable columns={columns} result={listResult} hideSearch hideExport hideColumnHeader />
		</>
	);
};
export default HandlerIncidentList;
