import React from "react";
import { Alert } from "reactstrap";
import { HelpIcon } from "./Icons";
const HelpDialog = (props) => {

    const [clicked, setClicked] = React.useState(false);
    const [hover, setHover] = React.useState(false);
    const { message } = props;

    return <> <span
        onMouseEnter={() => { setHover(true) }}
        onMouseLeave={() => { setHover(false) }}
        onClick={() => { setClicked(true) }}><HelpIcon onClick={() => {
    }} /></span>
        {(hover || clicked) && <Alert onClick={() => { setClicked(false); setHover(false);}} color="info"><>{message}</></Alert>}
    </>
}

export default HelpDialog;