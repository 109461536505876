import React, { useEffect, useMemo, useState } from "react";
import { useApi } from "components/useApi3";
import DataTable from "components/DataTable";

import PageHeader from "components/PageHeader";
import Panel from "components/Panel";
import { Button, ButtonGroup, Col, Form, FormGroup, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import AlertResultFeedback from "components/AlertResultFeedback";
import ItemForm from "./Form";
import LoadingSpinner from "components/LoadingSpinner";

const validationSchema = Yup.object().shape({
	name: Yup.string().required("Namn är obligatoriskt")
});

const CrudPage = ({match}) => {

    const apiBaseUrl = "api/superadmin/companies/".concat(match.params.id).concat("/sites/");


	const [listResult, listApi] = useApi();
	const [createResult, createApi] = useApi();
	const [editItemResult, editItemApi] = useApi();
	const [editResult, editApi] = useApi();
	const [deleteResult, deleteApi] = useApi();

	const [showCreate, setShowCreate] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [deleteId, setDeleteId] = useState(null);

	useEffect(() => {
		listApi.get(apiBaseUrl);
	}, [listApi,apiBaseUrl]);

	// post skapa success
	useEffect(() => {
		if (createResult.status === 2) {
			listApi.get(apiBaseUrl);
			createApi.reset();
			setShowCreate(false);
		}
	}, [createResult.status, listApi, createApi,apiBaseUrl]);

	// post delete success
	useEffect(() => {
		if (deleteResult.status === 2) {
			listApi.get(apiBaseUrl);
			deleteApi.reset();
			setShowDelete(false);
			setShowEdit(false);
			setDeleteId(null);
		}
	}, [deleteResult.status, listApi, deleteApi,apiBaseUrl]);

	// post edit success
	useEffect(() => {
		if (editResult.status === 2) {
			listApi.get(apiBaseUrl);
			editApi.reset();
			setShowEdit(false);
		}
	}, [editResult.status, listApi, editApi,apiBaseUrl]);

	const columns = useMemo(
		() => [
			{
				accessor: "name",
				Header: "Namn",
			},			
			{
				accessor: "commands",
				Cell: (cell) => {
					return (
						<ButtonGroup className="float-end">							
							<Button
								color="white"
								onClick={() => {
									editItemApi.get(apiBaseUrl.concat(cell.row.original.id));
									setShowEdit(true);
								}}
							>
								Redigera
							</Button>
						</ButtonGroup>
					);
				},
			},
		],
		[editItemApi,apiBaseUrl]
	);

	return (
		<>
			<PageHeader
				title="Orter"
				subtitle="Administrera orter"
				commands={
					<Button
						onClick={() => {
							setShowCreate(true);
						}}
						color="primary"
					>
						Lägg till ort
					</Button>
				}
			/>
			<Panel>
				<DataTable columns={columns} result={listResult} />
			</Panel>
			<Modal
				isOpen={showCreate}
				toggle={() => {
					setShowCreate(!showCreate);
				}}
				
				onClosed={() => {
                    createApi.reset();
				}}
			>
				<ModalHeader>Lägg till ort</ModalHeader>
				<ModalBody>
					<Row>
						<Col xs={12}>
							<Formik
								initialValues={{
									name: "",
                                    companyId: match.params.id
								}}
								onSubmit={(values) => {
									// same shape as initial values

									createApi.post(apiBaseUrl, values);
								}}
								validationSchema={validationSchema}
							>
								{({ errors, values, touched, handleChange, handleBlur, handleSubmit }) => (
									<Form onSubmit={handleSubmit} noValidate>
										<ItemForm values={values} errors={errors} touched={touched} handleBlur={handleBlur} handleChange={handleChange} />
										<FormGroup>
											<Row>
												<Col xs={6}>
													<Button
														block
														color="white"
														onClick={() => {
															setShowCreate(false);
														}}
													>
														Avbryt
													</Button>
												</Col>
												<Col xs={6}>
													<Button block disabled={createResult.status === 1} color="primary" type="submit">
														Lägg till
													</Button>
												</Col>
											</Row>
										</FormGroup>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<AlertResultFeedback result={createResult} />
						</Col>
					</Row>
				</ModalBody>
			</Modal>
			<Modal
				isOpen={showEdit}
				toggle={() => {
					setShowEdit(!showEdit);
				}}
				onClosed={() => {
                    editApi.reset();
				}}
			>
				<ModalHeader>Redigera bolag</ModalHeader>
				{editItemResult.status === 1 && <ModalBody><LoadingSpinner /></ModalBody> }				
				{editItemResult.status === 2 && (
					<ModalBody>
						<Row>
							<Col xs={12}>
								<Formik
									initialValues={{
										...editItemResult.data,
									}}
									onSubmit={(values) => {
										// same shape as initial values

										editApi.put(apiBaseUrl, values);
									}}
									validationSchema={validationSchema}
								>
									{({ errors, values, touched, handleChange, handleBlur, handleSubmit }) => (
										<Form onSubmit={handleSubmit} noValidate>
											<FormGroup>
												<Row>
													<Col>
														<Button
															disabled={editResult.status === 1}
															onClick={() => {
																setDeleteId(values.id);
																setShowDelete(true);
															}}
															className="float-end mt-2"
															color="danger"
															type="button"
														>
															Radera
														</Button>
													</Col>
												</Row>
											</FormGroup>
											<ItemForm
												handleSubmit={handleSubmit}
												handleBlur={handleBlur}
												values={values}
												errors={errors}
												touched={touched}
												handleChange={handleChange}
											/>
											<FormGroup>
												<Row>
													<Col xs={6}>
														<Button
															block
															color="white"
															onClick={() => {
																setShowEdit(false);
															}}
														>
															Avbryt
														</Button>
													</Col>
													<Col xs={6}>
														<Button block disabled={editResult.status === 1} color="primary" type="submit">
															Spara
														</Button>
													</Col>
												</Row>
											</FormGroup>
										</Form>
									)}
								</Formik>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<AlertResultFeedback result={editResult} />
							</Col>
						</Row>
					</ModalBody>
				)}
			</Modal>
			<Modal
				isOpen={showDelete}
				toggle={() => {
					setShowDelete(!showDelete);
				}}
				onClosed={() => {
                    deleteApi.reset();
				}}
			>
				<ModalHeader>Radera ort</ModalHeader>
				{deleteId && (
					<ModalBody>
						<h4>Är du säker du vill radera orten?</h4>
						<Row>
							<Col xs={6}>
								<Button
									block
									color="white"
									onClick={() => {
										setShowDelete(false);
										setDeleteId(null);
									}}
								>
									Avbryt
								</Button>
							</Col>
							<Col xs={6}>
								<Button
									disabled={deleteResult.status === 1}
									block
									color="danger"
									onClick={() => {
										deleteApi.del(apiBaseUrl.concat(deleteId));
									}}
								>
									Radera
								</Button>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<AlertResultFeedback result={deleteResult} />
							</Col>
						</Row>
					</ModalBody>
				)}
			</Modal>
		</>
	);
};
export default CrudPage;
